import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getProducto, getProductos } from "../../state/actions/ProductActions";
import { getAllCategories } from "../../state/actions/CategoryAction";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";
import Pagination from "@material-ui/lab/Pagination";
import DocumentBuy from "../Buy/DocumentBuy";
import DetailsProductUser from "../Product/DetailsProductUser";
import { Dialog, DialogActions } from "@material-ui/core";
import DetailsProductUserSoy from "./DetailsProductUserSoy";

const ProductsUser = (props) => {
  const [open, setOpen] = useState(false);
  const [openSoy, setOpenSoy] = useState(false);
  const [idProduct, setIdProduct] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");
  const [paginadorProductos, setPaginadorProductos] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    pageCount: 0,
    data: [],
  });

  const [requestProductos, setRequestProductos] = useState({
    pageIndex: 1,
    pageSize: 8,
    search: "",
  });

  useEffect(() => {
    const cargarCategorias = async () => {
      const response = await getAllCategories();
      setCategorias(response.data);
    };

    cargarCategorias();
  }, []);

  useEffect(() => {
    const getListaProductos = async () => {
      const response = await getProductos({
        ...requestProductos,
        category: categoriaSeleccionada,
      });
      setPaginadorProductos(response.data);
    };

    getListaProductos();
  }, [requestProductos, categoriaSeleccionada]);

  const verProducto = (id) => {
    setIdProduct(id);

    const getProductoAsync = async () => {
      const response = await getProducto(id);
      if (response.data.codeCategory == "CA") {
        setOpenSoy(true);
      } else {
        setOpen(true);
      }
    };
    getProductoAsync();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const closeDialogSoy = () => {
    setOpenSoy(false);
  };

  const handleChange = (event, value) => {
    setRequestProductos((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  const classes = makeStyles();
  const classesMain = useStylesMain();

  if (!paginadorProductos.data) {
    return null;
  }

  const handleChangeCategoria = (event) => {
    setCategoriaSeleccionada(parseInt(event.target.value, 10));
  };
  return (
    <>
      <Container className={classesMain.containermt} >
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h4" className={classesMain.text_title}>
              VENTA DE PRODUCTOS
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormControl variant="outlined" style={{ minWidth: '240px', width: '100%' }}>
              <InputLabel id="categoria-select-label">Filtrar por Categoria</InputLabel>
              <Select
                labelId="categoria-select-label"
                id="categoria-select"
                value={categoriaSeleccionada}
                onChange={handleChangeCategoria}
                label="Seleccione Categoría"

              >
                {categorias.map((categoria) => (
                  <MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {paginadorProductos.data.map((data) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={data.id}>
              <Card>
                <DocumentBuy
                  id={data.image}
                  price={data.priceSale}
                ></DocumentBuy>
                <CardContent>
                  <Typography variant="h6" className={classes.text_card}>
                    {data.name}
                  </Typography>
                  <Typography variant="h6" className={classes.text_card}>
                    {data.brandName}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => verProducto(data.id)}
                  >
                    VENDER
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={paginadorProductos.pageCount}
          page={paginadorProductos.pageIndex}
          onChange={handleChange}
        />
      </Container>
      <Dialog
        open={open}
        onClose={closeDialog}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DetailsProductUser
          idProduct={idProduct}
          setOpen={setOpen}
        ></DetailsProductUser>

        <DialogActions>
          <Button onClick={closeDialog} color="secondary" size="large"  variant="contained">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSoy}
        onClose={closeDialogSoy}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <DetailsProductUserSoy
          idProduct={idProduct}
          setOpenSoy={setOpenSoy}
        ></DetailsProductUserSoy>

        <DialogActions>
          <Button onClick={closeDialogSoy} color="secondary" size="large"  variant="contained">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductsUser;
