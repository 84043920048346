import {
  Button,
  Container,
  DialogActions,
  Grid,
  TextField,
} from "@material-ui/core";
import useStylesMain from "../../styles/useStylesMain";
import { updateConfirm } from "../../state/actions/ShoppingActions";

import { useState } from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const ConfirmShopping = ({ setOpenConfirm, refreshList, idProduct, total, cantidad, grupo, purchases }) => {
  const [invoce, setInvoce] = useState("");
  const [flete, setFlete] = useState(0.0);
  const [another, setAnother] = useState(0.0);
  const [purchase, setPurchase] = useState(purchases);
  const [winning, setWinning] = useState(0.0);
  const [sales, setSales] = useState(0.0);
  const classesMain = useStylesMain();
  const [dateFrom, setDateFrom] = useState(new Date());

  const handleChangeFlete = (e) => {
    let valueData = e.target.value;
    setFlete(valueData);
    calculePriceSale(parseFloat(valueData), parseFloat(another));
  };

  const calculePriceSale = (fleteData, anotherData) => {
    if (fleteData > 0 && anotherData > 0) {
      const result = fleteData + total + anotherData;
      setPurchase(result / cantidad);
    } else if (fleteData > 0) {
      const result = fleteData + total;
      setPurchase(result / cantidad);
    } else if (anotherData > 0) {
      const result = anotherData + total;
      setPurchase(result / cantidad);
    } else {
      setPurchase(total / cantidad);
    }
  };

  const handleChangeAnother = (e) => {
    let valueData = e.target.value;
    setAnother(valueData);
    calculePriceSale(parseFloat(flete), parseFloat(valueData));
  };

  const handleChangeInvoce = (e) => {
    setInvoce(e.target.value);
  };

  const handleChangeSale = (e) => {
    let valueData = e.target.value;
    setSales(valueData);
    if (parseFloat(valueData) > 0) {
      setWinning(valueData - purchase);
    } else {
      setWinning(0);
    }
  };

  const handleChangeWinning = (e) => {
    let valueData = e.target.value;
    setWinning(valueData);
    if (parseFloat(valueData) > 0) {
      setSales(purchase + parseFloat(valueData));
    } else {
      setSales(purchase);
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const confirmProductBuys = () => {
    const buy = {
      invoce: invoce,
      purchase: purchase,
      sale: sales,
      freight: flete,
      other: another,
      group: grupo || "0",
      dateConfirm : dateFrom,
    };

    updateConfirm(idProduct, buy).then((response) => {
      refreshList();
    });

    setOpenConfirm(false);
  };

  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={6} xs={12}>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateFrom}
                onChange={setDateFrom}
                margin="normal"
                id="fecha-publicacion-id"
                label="Fecha de confirmacion"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <br></br>

            <TextField
              label="Comprobante de compra"
              variant="outlined"
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              name="invoce"
              value={invoce}
              onChange={handleChangeInvoce}
            />

            <TextField
              label="Flete-Carguio-Descarguio"
              variant="outlined"
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              name="flete"
              value={flete}
              onChange={handleChangeFlete}
            />

            <TextField
              label="Otros gastos"
              variant="outlined"
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              name="another"
              value={another}
              onChange={handleChangeAnother}
            />

            <TextField
              label="Precio de compra"
              variant="outlined"
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              name="purchase"
              value={purchase}
            />

            <TextField
              label="Ingrese ganancia"
              variant="outlined"
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              name="winning"
              value={winning}
              onChange={handleChangeWinning}
            />

            <TextField
              label="precio de venta"
              variant="outlined"
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              name="sales"
              value={sales}
              onChange={handleChangeSale}
            />
          </form>
        </Grid>
      </Grid>

      <DialogActions>
        <Button
          onClick={confirmProductBuys}
          color="primary"
          fullWidth
          variant="contained"
        >
          Confirmar
        </Button>
        <Button
          onClick={handleCloseConfirm}
          color="secondary"
          fullWidth
          autoFocus
          variant="contained"
        >
          Cancelar
        </Button>
      </DialogActions>
    </Container>
  );
};

export default ConfirmShopping;
