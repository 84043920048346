import {
  Avatar,
  Button,
  Card,
  Container,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { getAllRoles } from "../../state/actions/RoleActions";
import { registerUser } from "../../state/actions/UserActions";
import { useStateValue } from "../../state/store";
import useStylesMain from "../../styles/useStylesMain";

const RegisterUser = ({ setOpenAdd, refreshList }) => {
  const [socio, setSocio] = useState(false);
  const [{ }, dispatch] = useStateValue();
  const [user, setUser] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
    username: "",
    code: "",
    bank: "",
    phone: "",
    group: 0,
    rolName:"",
  });

  const [role, setRole] = useState(null);

  const [roles, setRoles] = useState({
    data: [],
  });


  useEffect(() => {
    const getListRoles = async () => {
      const response = await getAllRoles();
      setRoles(response.data);
    };

    getListRoles();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveUser = () => {
    user.rolName = role.rolName
    registerUser(user, dispatch).then((response) => {
      refreshList();
    });

    setOpenAdd(false);
  };

  const classes = useStylesMain();
  return (
    <Container className={classes.containermt}>
      <Grid container justify="center">
        <Grid item lg={12} md={12}>
          <Card className={classes.card} align="center">
            <Avatar className={classes.avatar}>
              <Icon className={classes.icon}>person_add</Icon>
            </Avatar>
            <Typography variant="h5" color="primary">
              Registro de Usuario
            </Typography>

            <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <Autocomplete
                    options={roles}
                    getOptionLabel={(option) => option.rolName}
                    id="controlled-demo"
                    value={role}
                    onChange={(event, newValue) => {
                      setRole(newValue);
                      if(newValue.rolName === "Socio"){
                        setSocio(true)
                      } else{
                        setSocio(false)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rol"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>


                {socio && (
                  <>
                    <Grid item md={12} xs={12} className={classes.gridmb}>
                      <TextField
                        label="Codigo"
                        variant="outlined"
                        fullWidth
                        name="code"
                        value={user.code}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={12} xs={12} className={classes.gridmb}>
                      <TextField
                        label="Cuenta banco"
                        variant="outlined"
                        fullWidth
                        name="bank"
                        value={user.bank}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}

                {socio && (
                  <Grid item md={12} xs={12} className={classes.gridmb}>
                    <TextField
                      label="Grupo"
                      variant="outlined"
                      fullWidth
                      name="group"
                      value={user.group}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Nombre"
                    variant="outlined"
                    fullWidth
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Apellidos"
                    variant="outlined"
                    fullWidth
                    name="lastName"
                    value={user.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    name="username"
                    value={user.username}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Telefono"
                    variant="outlined"
                    fullWidth
                    name="phone"
                    value={user.phone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={saveUser}
                    type="submit"
                  >
                    Registrar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container >
  );
};

export default RegisterUser;
