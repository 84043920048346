import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import useStylesMain from '../../styles/useStylesMain';
import DebtExcel from './DebtExcel';
import DebtReport from './DebtReport';


const AllDebt = () => {
  const classes = useStylesMain();


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item >
          <Paper className={classes.card}>
            <DebtExcel></DebtExcel>
          </Paper>
        </Grid>
        <Grid item >
          <Paper className={classes.card}>
            <DebtReport></DebtReport>
          </Paper>
        </Grid>

      </Grid>
    </div>
  );
};

export default AllDebt;
