import {
  Button,
  Divider,
  Icon,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  getListAssociateExcel,
} from "../../state/actions/UserActions";
const Associate = () => {

  const report = () => {
    const listExcel = async () => {
      const res = await getListAssociateExcel();
      const file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };
    listExcel();
  };

  return (
    <>
      <Typography variant="h6" >
        REPORTE DE SOCIOS
      </Typography>

      <Divider />
      <br></br>
      <Button variant="contained" color="secondary" onClick={report}>
        <Icon>search</Icon>
        GENERAR EXCEL
      </Button>
    </>
  );
};

export default Associate;
