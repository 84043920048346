import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_URL_BASE;

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("token_seguridad");

    if (token) {
      config.headers.Authorization = "Bearer " + token;
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AxiosRequest = {
  getOnly: (url) => axios.get(url), 
  getByJason: (url, parameters) => axios.get(url, { params: parameters }),
  post: (url, body) => axios.post(url, body),
  put: (url, body) => axios.put(url, body),
  putId: (url) => axios.put(url),
  delete: (url) => axios.delete(url),
  getByJasonPdf: (url, parameters) => 
  axios.get(url, { 
    params: parameters,
    responseType: "blob",
  })
};

export default AxiosRequest;
