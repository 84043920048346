import React from 'react';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import  Traffic  from  '@material-ui/icons/Traffic';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import makeStyles from "./styles";
import {Link } from "react-router-dom";

const  Navigator = (props) => {
  const classes = makeStyles();
  const {...other } = props;

  const categories = [
    {
      
      children: [
        { id: 'Usuario', icon: <PeopleIcon />, active: true, path:'/users'}
      ],
    },
    {
      children: [
        { id: 'Lecheria', icon: <EmojiPeopleIcon />, path:'/pil' },
        
      ],
    },
    {
      
      children: [
        { id: 'Productos', icon: <SettingsIcon />, path:'/products' }
      ],
    },
    {
      
      children: [
        { id: 'Pedidos/Compras', icon: <StoreIcon />, path:'/shopping' }
      ],
    },
    {
      
      children: [
        { id: 'Ventas', icon: <ShoppingCartIcon />, path:'/buys' }
      ],
    },
    {

      children: [
        { id: 'Reportes', icon: <PeopleAltIcon />, path:'/report' },
      ],
    },
  ];
  

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <img src="ALMlogoB.png" alt="logo" style={{ width: "90%"}} />
        </ListItem>
        
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            {/* <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem> */}
            {children.map(({ id: childId, icon, active, path }) => (
              <ListItem
                key={childId}
                button
                className={clsx(classes.item, active && classes.itemActiveItem)}
                component={Link} to={path}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                  
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
         ))} 
      </List>
    </Drawer>
  );
}

export default Navigator;