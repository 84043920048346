import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    buttonAgregar : {
        float: "right"
    },
    avatarProducto : {
        width: 175,
        height: 175,
        backgroundColor: "#F2F2F2"
    },
    media : {
        height: 150,
        backgroundColor: "#F2F2F2",
        margin: "15px 15px 0 15px"
    },
    price : {
        float: "right",
        padding: "0 40px 0 40px",
        backgroundColor: "#0f80aa"
    },
    text_card: {
        fontWeight: "bold",
        color: "#656565",
        marginBottom: 8
    },
    PaperImg : {
        backgroundColor: "#F2F2F2"
    },
    mediaDetalle : {
        width: 330,
        height: 350,
        margin: "auto"
    },
    mediaTotal : {
        width: 330
    },
    text_detalle: {
        fontWeight: 500,
        color: "#494949",
        marginBottom: 2
    },
    imgProductoCC : {
        backgroundColor: "#F2F2F2",
        width: 80,
        height: 70
    },
    papperPadding : {
        padding: 10,
        marginLeft:0,
        marginRight:0,
    },
    text_envio : {
        lineHeight: 3
    },
    alertNotDelivered : {
        marginTop: 5,
        padding: "15px 15px 5px 15px",
        marginBottom: 20,
        backgroundColor: "#ffcccc"
    },
    alertDelivered : {
        marginTop: 5,
        padding: "15px 15px 5px 15px",
        marginBottom: 20,
        backgroundColor: "#d6f5d6"
    },
    divider :{
        marginTop: 12,
        marginBottom: 12
    },
    imgProductoPC : {
        backgroundColor: "#F2F2F2",
        width: 50,
        height: 40
    },
    gridPC : {
        margin: "auto",
        marginTop: 20
    },
    buttonAnterior : {
        marginRight: 8
    },
    formControl : {
        margin: 12
    },
    gridLR : {
        paddingLeft: 30,
        paddingBottom: 20,
        paddingRight: 30
    },
    text_detalleS: {
        marginBottom: theme.spacing(1),
      },
      dividerS: {
        margin: theme.spacing(3, 0),
      },
      button: {
        marginTop: theme.spacing(2),
      },
}))