import {
  Avatar,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState, useCallback } from "react";
import ImageUploader from "react-images-upload";
import { getAllCategories } from "../../state/actions/CategoryAction";
import { obtenerDataImagen } from "../../state/actions/ImageAction";
import {
  actualizarProducto,
  getAllBrands,
  getProducto,
} from "../../state/actions/ProductActions";
import { useStateValue } from "../../state/store";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";

const EditProduct = ({ setOpenUpdate, refreshList, idProduct }) => {
  const [{}, dispatch] = useStateValue();
  const [kg, setKg] = useState(false);
  const [brand, setBrand] = useState(null);

  const [brands, setBrands] = useState({
    data: [],
  });

  const [category, setCategory] = useState(null);

  const [categories, setCategories] = useState({
    data: [],
  });

  const [producto, setProducto] = useState({
    name: "",
    description: "",
    stockMax: 0,
    stockMin: 0,
    brandId: 0,
    categoryId: 0,
    imageGeneral: null,
    imagenTemporal: "",
    image: "",
    isInKg: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [brandsResponse, categoriesResponse, productResponse] = await Promise.all([
          getAllBrands(),
          getAllCategories(),
          getProducto(idProduct),
        ]);
        setBrands(brandsResponse.data);
        setCategories(categoriesResponse.data);
        
        const updatedProduct = productResponse.data;
        const fotoPerfil = updatedProduct.imageGeneral;
        const nuevoFile = `data:image/${fotoPerfil.extension};base64,${fotoPerfil.data}`;
        updatedProduct.imagenTemporal = nuevoFile;
        
        setProducto(updatedProduct);
        setKg(updatedProduct.isInKg);
        setBrand(brandsResponse.data.find(b => b.id === updatedProduct.brandId));
        const productCategory = categoriesResponse.data.find(c => c.id === updatedProduct.categoryId);
        setCategory(productCategory);

      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idProduct]); // Dependencia: idProduct

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProducto((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const subirImagen = (imagenes) => {
    let foto = imagenes[0];

    let fotoUrl = "";
    try {
      fotoUrl = URL.createObjectURL(foto);
    } catch (e) {
      console.log(e);
    }

    obtenerDataImagen(foto).then((respuesta) => {
      setProducto((prev) => ({
        ...prev,
        imageGeneral: respuesta,
        imagenTemporal: fotoUrl,
      }));
    });
  };

  const updateProducto = useCallback(async (e) => {
    e.preventDefault();
    const updatedProduct = {
      ...producto,
      brandId: brand.id,
      categoryId: category.id,
    };
    const response = await actualizarProducto(idProduct, updatedProduct);

    if (response.status === 200) {
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "Se actualizo correctamente",
        },
      });
      setOpenUpdate(false);
      refreshList();
    } else {
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "No es posible actualizar",
        },
      });
    }
  }, [idProduct, brand, category, producto, dispatch, refreshList, setOpenUpdate]);

  const handleChangeKg = (e) => {
    setKg(e.target.checked);
    producto.isInKg = e.target.checked;
  };

  const classesMain = useStylesMain();
  const classes = makeStyles();
  return (
    <Container className={classes.containermt}>
      <Grid container justify="center">
        <Grid item sm={6} xs={12}>
          <Typography variant="h5" className={classes.text_title}>
            EDITAR PRODUCTO
          </Typography>
          <form onSubmit={(e) => e.preventDefault()} className={classes.form}>
            <FormControl className={classesMain.checkbox}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Es su producto en KG?"
                checked={kg}
                onChange={handleChangeKg}
              />
            </FormControl>

            <TextField
              label="Nombre Producto"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="name"
              value={producto.name}
              onChange={handleChange}
            />
            <TextField
              label="Stock Minimo"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="stockMin"
              value={producto.stockMin}
              onChange={handleChange}
            />

            <TextField
              label="Stock maximo"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="stockMax"
              value={producto.stockMax}
              onChange={handleChange}
            />

            <TextField
              label="Descripcion"
              variant="outlined"
              multiline
              rows={2}
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="description"
              value={producto.description}
              onChange={handleChange}
            />

            <Autocomplete
              options={brands}
              defaultValue={brand}
              getOptionLabel={(option) => option.name}
              id="controlled-demo"
              value={brand}
              onChange={(event, newValue) => {
                setBrand(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Marca"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>

            <Autocomplete
              options={categories}
              defaultValue={category}
              getOptionLabel={(option) => option.name}
              id="controlled-demo"
              value={category}
              onChange={(event, newValue) => {
                setCategory(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categoria"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>

            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <ImageUploader
                  withIcon={true}
                  singleImage={true}
                  buttonText="Buscar Imagen"
                  imgExtension={[".jpg", ".jpeg", ".png", ".gif"]}
                  maxFileSize={5242880}
                  onChange={subirImagen}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Avatar
                  variant="square"
                  className={classes.avatarProducto}
                  src={
                    producto.imagenTemporal
                  }
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={updateProducto}
            >
              ACTUALIZAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditProduct;
