import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import {
  deleteContributionAssocaite,
  getContributionDelete,
} from "../../state/actions/PilActions";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "../Product/styles";

const Contribution = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [idContri, setIdContri] = useState("");
  const [code, setCode] = useState("");


  const [requestProductos, setRequestProductos] = useState({
    pageIndex: 1,
    pageSize: 20,
    search: "",
  });

  const [paginadorProductos, setPaginadorProductos] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    pageCount: 0,
    data: [],
  });

  const handleChange = (event, value) => {
    setRequestProductos((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  useEffect(() => {
    const getListaProductos = async () => {
      const response = await getContributionDelete(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaProductos();
  }, [requestProductos]);

  const classes = makeStyles();
  const classesMain = useStylesMain();

  const searchDate = () => {
    const newRequest = {
      pageIndex: 1,
      pageSize: 20,
      search: code,
    }
    const getListaProductos = async () => {
      const response = await getContributionDelete(newRequest);
      setPaginadorProductos(response.data);
    };

    getListaProductos();
  };

  const deleteContrinution = (id, date) => {
    setIdContri(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const deleteContri = () => {

   

    deleteContributionAssocaite(idContri).then((response) => {
      const getListaProductos = async () => {
        setRequestProductos((anterior) => ({
          ...anterior,
          search: code,
        }));
        const response = await getContributionDelete(requestProductos);
        setPaginadorProductos(response.data);
      };

      getListaProductos();
    });
    setOpenDelete(false);
  };

  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  return (
    <Container className={classesMain.containermt}>
      <Grid container>
        <Grid item lg={12} sm={12} xs={12}>
          <Typography
            component={"span"}
            variant="h4"
            className={classesMain.text_title}
          >
            APORTES SOCIO
          </Typography>
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            label="Codigo de socio"
            variant="outlined"
            className={classes.gridmb}
            InputLabelProps={{
              shrink: true,
            }}
            name="code"
            value={code}
            onChange={handleChangeCode}
          />
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonAgregar}
            onClick={searchDate}
          >
            <Icon>search</Icon>
            BUSCAR
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>APORTE</TableCell>
              <TableCell>MONTO</TableCell>
              <TableCell>FECHA APORTE</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginadorProductos.data.map((producto) => (
              <TableRow key={producto.id}>
                <TableCell>{producto.contributionName}</TableCell>
                <TableCell>{producto.price}</TableCell>
                <TableCell>{producto.date}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => deleteContrinution(producto.id)}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginadorProductos.pageCount}
        page={paginadorProductos.pageIndex}
        onChange={handleChange}
      />

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar Aporte</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de eliminar el aporte de este socio?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteContri} color="primary" fullWidth variant="contained">
            Eliminar
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="secondary"
            autoFocus
            variant="contained"
            fullWidth
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
};

export default Contribution;
