import {
  Avatar,
  Button,
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";
import ImageUploader from "react-images-upload";
import { v4 as uuidv4 } from "uuid";
import {
  getAllBrands,
  registrarProducto,
} from "../../state/actions/ProductActions";
import { obtenerDataImagen } from "../../state/actions/ImageAction";
import { Autocomplete } from "@material-ui/lab";
import { getAllCategories } from "../../state/actions/CategoryAction";

const AddProduct = ({ setOpen, refreshList }) => {
  const [kg, setKg] = useState(false);
  const imagenDefault =
    "https://tottope.vteximg.com.br/arquivos/ids/167188-1000-1000/PILIGRAM-H-1810-V07_A.png?v=636723781789170000";
  const [producto, setProducto] = useState({
    name: "",
    description: "",
    stockMax: 0,
    stockMin: 0,
    brandId: 0,
    categoryId: 0,
    imageGeneral: null,
    imagenTemporal: null,
    isInKg: false,
  });

  const [brand, setBrand] = useState(null);

  const [brands, setBrands] = useState({
    data: [],
  });

  const [category, setCategory] = useState(null);

  const [categories, setCategories] = useState({
    data: [],
  });

  useEffect(() => {
    const getListBrands = async () => {
      const response = await getAllBrands();
      setBrands(response.data);
    };

    getListBrands();

    const getListCategories = async () => {
      const response = await getAllCategories();
      setCategories(response.data);
    };

    getListCategories();
  }, []);

  const guardarProducto = async () => {
    producto.brandId = brand.id;
    producto.categoryId = category.id;

    registrarProducto(producto).then((response) => {
      refreshList();
    });

    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProducto((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const subirImagen = (imagenes) => {
    let foto = imagenes[0];

    let fotoUrl = "";
    try {
      fotoUrl = URL.createObjectURL(foto);
    } catch (e) {
      console.log(e);
    }

    obtenerDataImagen(foto).then((respuesta) => {
      setProducto((prev) => ({
        ...prev,
        imageGeneral: respuesta,
        imagenTemporal: fotoUrl,
      }));
    });
  };

  const keyImage = uuidv4();

  const classes = makeStyles();
  const classesMain = useStylesMain();

  const handleChangeKg = (e) => {
    setKg(e.target.checked);
    producto.isInKg = e.target.checked;
  };
  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={10} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR PRODUCTO
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <FormControl className={classesMain.checkbox}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Es su producto en KG?"
                checked={kg}
                onChange={handleChangeKg}
              />
            </FormControl>
            <TextField
              label="Nombre Producto"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="name"
              value={producto.name}
              onChange={handleChange}
            />
            <TextField
              label="Stock Minimo"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="stockMin"
              value={producto.stockMin}
              onChange={handleChange}
            />

            <TextField
              label="Stock maximo"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="stockMax"
              value={producto.stockMax}
              onChange={handleChange}
            />

            <TextField
              label="Descripcion"
              variant="outlined"
              multiline
              rows={1}
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="description"
              value={producto.description}
              onChange={handleChange}
            />

            <Autocomplete
              options={brands}
              getOptionLabel={(option) => option.name}
              id="controlled-demo"
              value={brand}
              onChange={(event, newValue) => {
                setBrand(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Marca"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>

            <Autocomplete
              options={categories}
              getOptionLabel={(option) => option.name}
              id="controlled-demo"
              value={category}
              onChange={(event, newValue) => {
                setCategory(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categoria"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>

            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <ImageUploader
                  withIcon={true}
                  singleImage={true}
                  key={keyImage}
                  buttonText="Buscar Imagen"
                  imgExtension={[".jpg", ".jpeg", ".png", ".gif"]}
                  maxFileSize={5242880} /* bytes */
                  onChange={subirImagen}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Avatar
                  variant="square"
                  className={classes.avatarProducto}
                  src={
                    producto.imagenTemporal
                      ? producto.imagenTemporal
                      : imagenDefault
                  }
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={guardarProducto}
            >
              AGREGAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddProduct;
