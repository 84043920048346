import axios from 'axios';
import AxiosRequest from "../axios/AxiosRequest";

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const obtenerDataImagen = imagen => {
    return new Promise((resolve, eject) => {
        const nombre = imagen.name;
        const extension = imagen.name.split(".").pop();

        const lector = new FileReader();
        lector.readAsDataURL(imagen);
        
        lector.onload = () => resolve(
            {
                data : lector.result.split(",")[1],
                name : nombre,
                extension : extension
            }    
        );
         lector.onerror = error => PromiseRejectionEvent(error);
    })
}

export const getDocument =  id => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/document/${id}`)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            resolve(error.response);
        });
    });
}

