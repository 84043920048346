import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import {
  getSalesPay,
} from "../../state/actions/ProductActions";
import useStylesMain from "../../styles/useStylesMain";
import Discount from "./Discount";
import CloseIcon from '@material-ui/icons/Close';

const Pays = () => {
  const classes = useStylesMain();
  const [idAssociate, setIdAssociate] = useState(0);
  const [openDiscount, setOpenDiscount] = useState(false);
  const [group, setGroup] = useState(0);

  const [itemData, setItemData] = useState({
    id: 0,
    name: "",
    lastName: "",
    code: "",
    price: 0,
  });

  const [paginadorUsuarios, setPaginadorUsuarios] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    PageCount: 0,
    data: [],
  });

  const [requestSales, setRequestSales] = useState({
    pageIndex: 1,
    pageSize: 10,
    search: "",
    group: 1,
  });

  useEffect(() => {
    const getListaProductos = async () => {
      const response = await getSalesPay(requestSales);
      setPaginadorUsuarios(response.data);
    };

    getListaProductos();
  }, [requestSales]);

  const verDetalleDiscount = (item) => {
    setItemData((anterior) => ({
      ...anterior,
      id: item.id,
      name: item.name,
      lastName: item.lastName,
      code: item.code,
      price: item.price,
      group: item.group,
    }));

    setIdAssociate(item.id);
    setOpenDiscount(true);
  };

  const handleChange = (event, value) => {
    setRequestSales((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  const handleCloseDiscount = () => {
    setOpenDiscount(false);
  };



  const handleChangeGroup = (e) => {
    setGroup(e.target.value);
  };

  const handleChangeSearch = () => {
    setRequestSales((prev) => ({
      ...prev,
      search: group,
      pageIndex: 1,
    }));
  };

  const addSelectionPay = async () => {
    const getListaProductos = async () => {
      const response = await getSalesPay(requestSales);
      setPaginadorUsuarios(response.data);
    };

    getListaProductos();
  };

  return (
    <>
      <Container className={classes.containermt}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <TextField
              label="Ingrese codigo"
              variant="outlined"
              className={classes.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="group"
              value={group}
              onChange={handleChangeGroup}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleChangeSearch}
              fullWidth
            >
              BUSCAR
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Typography variant="h4" className={classes.text_title}>
          PAGOS
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Codigo</TableCell>
                <TableCell>Deuda</TableCell>
                <TableCell>ENTREGADO</TableCell>
                <TableCell>PAGADO</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginadorUsuarios.data.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.lastName}</TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.price} Bs</TableCell>
                  <TableCell>
                    <Icon className={classes.iconDelivered}>check</Icon>
                  </TableCell>
                  <TableCell>
                    <Icon className={classes.iconNotDelivered}>clear</Icon>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => verDetalleDiscount(item)}
                    >
                      PAGAR
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          count={paginadorUsuarios.pageCount}
          page={paginadorUsuarios.pageIndex}
          onChange={handleChange}
        />
      </Container>

      <Dialog
        open={openDiscount}
        onClose={handleCloseDiscount}
        fullWidth={true}
        fullScreen={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDiscount}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Discount
          itemData={itemData}
          setOpenDiscount={setOpenDiscount}
          addSelectionPay={addSelectionPay}
        >

        </Discount>
        <DialogActions>
          <Button
            onClick={handleCloseDiscount}
            autoFocus
            color="primary"
            variant="contained"
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Pays;
