import {
  Avatar,
  Button,
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "../Product/styles";
import { registerPil } from "../../state/actions/PilActions";
import { useStateValue } from "../../state/store";

const AddPil = ({ setOpen, refreshList }) => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [pil, setPil] = useState({
    code: "",
    milk: 0,
    soy: 0
  });

  const saveMilk = async () => {

    registerPil(pil).then((response) => {
      if (response.status === 200) {
        refreshList();
      }
      else
      {
        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: "No existe el socio con el codigo mencionado",
          },
        });
      }
     
    });

    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPil((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const classes = makeStyles();
  const classesMain = useStylesMain();

  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={8} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR DETALLES
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <TextField
              label="Codigo Socio"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="code"
              value={pil.code}
              onChange={handleChange}
            />
            <TextField
              label="Cupo leche"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="milk"
              value={pil.milk}
              onChange={handleChange}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={saveMilk}
            >
              AGREGAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddPil;
