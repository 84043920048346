import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { getContribution, deleteEntrance } from "../../state/actions/PilActions";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "../Product/styles";
import AddEntrance from "./AddEntrance";
import EditEntrance from "./EditEntrance";

const Entrance = () => {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idProduct, setIdProduct] = useState("");

  const handleCloseAdd = () => {
    setOpen(false);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };


  const [requestProductos, setRequestProductos] = useState({
    pageIndex: 1,
    pageSize: 20,
    search: "",
  });

  const [paginadorProductos, setPaginadorProductos] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    pageCount: 0,
    data: [],
  });

  const handleChange = (event, value) => {
    setRequestProductos((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  useEffect(() => {
    const getListaProductos = async () => {
      const response = await getContribution(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaProductos();
  }, [requestProductos]);

  const classes = makeStyles();
  const classesMain = useStylesMain();

  const agregarProducto = () => {
    setOpen(true);
  };

  const refreshList = () => {
    const getListaProductos = async () => {
      const response = await getContribution(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaProductos();
  };

  const deleteProduct = () => {
    deleteEntrance(idProduct).then((response) => {
      const getListaProductos = async () => {
        const response = await getContribution(requestProductos);
        setPaginadorProductos(response.data);
      };
  
      getListaProductos();
    });

    setOpenDelete(false);
  };

  const editaProducto = (id) => {
    setIdProduct(id);
    setOpenUpdate(true);
  };

  const removerProducto = (id) => {
    setOpenDelete(true);
    setIdProduct(id);
  };

  return (
    <Container className={classesMain.containermt}>
      <Grid container>
        <Grid item lg={6} sm={6} xs={12}>
          <Typography
            component={"span"}
            variant="h4"
            className={classesMain.text_title}
          >
            APORTES
          </Typography>
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonAgregar}
            onClick={agregarProducto}
          >
            <Icon>add</Icon>
            NUEVO APORTE
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>APORTE</TableCell>
              <TableCell>CODIGO</TableCell>
              <TableCell>MONTO</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginadorProductos.data.map((producto) => (
              <TableRow key={producto.id}>
                <TableCell>{producto.name}</TableCell>
                <TableCell>{producto.code}</TableCell>
                <TableCell>{producto.price}</TableCell>
                <TableCell>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => editaProducto(producto.id)}
                  >
                    <Icon>edit</Icon>
                  </Button>
                  <Button
                    onClick={() => removerProducto(producto.id)}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginadorProductos.pageCount}
        page={paginadorProductos.pageIndex}
        onChange={handleChange}
      />

      <Dialog
        open={open}
        onClose={handleCloseAdd}
        aria-labelledby="form-dialog-title"
      >
        <AddEntrance setOpen={setOpen} refreshList={refreshList}></AddEntrance>

        <DialogActions>
          <Button onClick={handleCloseAdd} fullWidth color="secondary" variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="form-dialog-title"
      >
        <EditEntrance
             setOpenUpdate={setOpenUpdate}
             refreshList={refreshList}
             idProduct={idProduct}
        ></EditEntrance>

        <DialogActions>
          <Button
            onClick={handleCloseUpdate}
            color="primary"
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de eliminar ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteProduct} color="primary" variant="contained">
            Eliminar
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="primary"
            autoFocus
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Entrance;
