import { Button, Container, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { editPil, getPil, getPilOnly } from "../../state/actions/PilActions";
import { useStateValue } from "../../state/store";
import useStylesMain from "../../styles/useStylesMain";

const EditPil = ({ setOpenUpdate, refreshList, idProduct  }) => {
  const [{ }, dispatch] = useStateValue();
  const [pil, setPil] = useState({
    id: idProduct,
    milk: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPil((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getProductIdAsync = async () => {
      const response = await getPilOnly(idProduct);
      setPil(response.data);      
    };

    getProductIdAsync();
  }, [idProduct]);

  const updatePil = async (e) => {
    e.preventDefault();

    const response = await editPil(pil);

    if (response.status === 200) {
      setOpenUpdate(false);
      refreshList();
    } else {
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "No es posible actualizar",
        },
      });
    }
  };

  const classesMain = useStylesMain();
  return (
    <Container className={classesMain.containermt}>
      <form onSubmit={(e) => e.preventDefault()} className={classesMain.form}>
        <TextField
          label="Cupo leche"
          variant="outlined"
          fullWidth
          className={classesMain.gridmb}
          InputLabelProps={{
            shrink: true,
          }}
          name="milk"
          value={pil.milk}
          onChange={handleChange}
        />
        <Button variant="contained" color="primary" fullWidth onClick={updatePil}>
          ACTUALIZAR
        </Button>
      </form>
    </Container>
  );
};

export default EditPil;
