import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getDriverById, updateDriverList } from "../../state/actions/TractorActions";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "../Product/styles";

const EditDriver = ({ idUser, setOpen, refreshList }) => {
  const classes = makeStyles();
  const classesMain = useStylesMain();

  const [usuario, setUsuario] = useState({
    id: idUser,
    driver: "",
    address: "",
    phone: 0,
    plate: "",
    license: "",
  });

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    setUsuario((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getUsuarioIdAsync = async () => {
      const response = await getDriverById(idUser);
      setUsuario(response.data);
    };

    getUsuarioIdAsync();
  }, [idUser]);

  const actualizarRoleUsuario = async (e) => {
    e.preventDefault();
    const response = await updateDriverList(idUser, usuario);

    if (response.status === 200) {
    } else {
    }
    setOpen(false);
    refreshList();
  };
  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item lg={12} sm={12}>
          <Typography variant="h4" className={classesMain.text_title}>
            Editar conductor
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <TextField
              label="Nombre del conductor"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              name="driver"
              value={usuario.driver}
              onChange={handleChangeUser}
            />
            <TextField
              label="Direccion"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              name="address"
              value={usuario.address}
              onChange={handleChangeUser}
            />
            <TextField
              label="Telefono"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              value={usuario.phone}
              name="phone"
              onChange={handleChangeUser}
            />
            <TextField
              label="Placa"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              value={usuario.plate}
              name="plate"
              onChange={handleChangeUser}
            />
            <TextField
              label="Licencia"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              value={usuario.license}
              name="license"
              onChange={handleChangeUser}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={actualizarRoleUsuario}
            >
              Actualizar
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditDriver;
