import AxiosRequest from "../axios/AxiosRequest";
import axios from "axios";

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const getUserById = (id) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.getOnly(`/api/user/account/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const addRole = (id, role, dispatch) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.put(`/api/user/role/${id}`, role)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const deleteUserList = (id) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post(`/api/user/deleteUser/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const updateUserList = (id, user) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.put(`/api/user/updateList/${id}`, user)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const updatePercentage = (group) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.put(`/api/user/percentage/${group}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
}

export const getUsers = (request) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.getOnly(
      `/api/user/pagination?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getAssociates = (request) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.getOnly(
      `/api/user/associates?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const updateUsuario = async (id, usuario, dispatch) => {
  /*if(usuario.file){
      const urlImage = await uploadImage(usuario.file);
      usuario.imagen = urlImage;
  }*/
  return new Promise((resolve, eject) => {
    AxiosRequest.put(`/api/user/update/${id}`, usuario)
      .then((response) => {
        dispatch({
          type: "ACTUALIZAR_USUARIO",
          nuevoUsuario: response.data,
          autenticado: true,
        });
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const registerUser = (usuario, dispatch) => {
  return new Promise((resolve, eject) => {
    instancia
      .post("/api/user/register", usuario)
      .then((response) => {
       /* dispatch({
          type: "INICIAR_SESION",
          sesion: response.data,
          autenticado: true,
        });*/

        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const loginUser = (usuario, dispatch) => {
  return new Promise((resolve, eject) => {
    instancia
      .post("/api/user/login", usuario)
      .then((response) => {
        dispatch({
          type: "INICIAR_SESION",
          sesion: response.data,
          autenticado: true,
        });

        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getUser = (dispatch) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.getOnly("/api/user")
      .then((response) => {
        dispatch({
          type: "INICIAR_SESION",
          sesion: response.data,
          autenticado: true,
        });

        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getListAssociateExcel = () => {
  return new Promise((resolve) => {
    AxiosRequest.getByJasonPdf("/api/user/getAssociateExcel").then((response) => {
      resolve(response);
    });
  });
};

export const getListContributionExcel = (item) => {
  return new Promise((resolve) => {
    AxiosRequest.getByJasonPdf("/api/user/contributionExcel", item).then((response) => {
      resolve(response);
    });
  });
};

export const getDebtExcel = (request) => {
  return new Promise((resolve) => {
    AxiosRequest.getOnly(`/api/user/getDebtExcel?code=${request.code}&dateFrom=${request.dateFrom}&dateTo=${request.dateTo}`).then((response) => {
      resolve(response);
    });
  });
};
