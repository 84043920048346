import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  Box,  
  ListItem,  
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import Perfil from "./Perfil";
import Users from "./Users";
import makeStyles from "./styles";
import { Link, withRouter } from "react-router-dom";
import { useStateValue } from "../../state/store";
import { useHistory } from "react-router-dom";
import Roles from "./Roles";

const TabUser = ({ onDrawerToggle }) => {
  const classes = makeStyles();
  const [value, setValue] = useState(0);
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const salirSesion = (e) => {
    localStorage.removeItem("token_seguridad");
    dispatch({
      type: "SALIR_SESION",
      nuevoUsuario: null,
      autenticado: false,
    });
    history.push("/login");
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item>
              <Typography variant="h5" component="h2" align="center">
               USUARIOS
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <IconButton
                color="inherit"
                className={classes.iconButtonAvatar}
                onClick={handleClick}
              >
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
              </IconButton>
              <Menu
                elevation={2}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem className={classes.listItem} onClick={handleClose}>
                  <Link className={classes.linkAppBarMobile} to="/">
                    <ListItem button onClick={salirSesion}>
                      <ListItemText>Cerrar Sesion</ListItemText>
                    </ListItem>
                  </Link>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Tabs value={value} onChange={handleChange} textColor="inherit">
          <Tab textColor="inherit" label="Listado" {...a11yProps(0)} />
          <Tab textColor="inherit" label="Perfil" {...a11yProps(1)} />
          <Tab textColor="inherit" label="Roles" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Users></Users>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Perfil></Perfil>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Roles></Roles>
      </TabPanel>
    </React.Fragment>
  );
};

export default withRouter(TabUser);
