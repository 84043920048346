import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
const Footer = () => {
    return (
      <Typography variant="body2" color="primary" align="center">
        {"Derechos Reservados © "}
        <Link color="inherit" href="#">
          En progreso  2021
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  export default Footer;