import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import useStylesMain from '../../styles/useStylesMain';
import ProductReport from './ProductReport';
import BuyProduct from './BuyProduct';
import Merge from './Merge';


const AllProduct = () => {
  const classes = useStylesMain();


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item >
          <Paper className={classes.card}>
            <ProductReport></ProductReport>
          </Paper>
        </Grid>
        <Grid item >
          <Paper className={classes.card}>
            <BuyProduct></BuyProduct>
          </Paper>
        </Grid>
        <Grid item >
          <Paper className={classes.card}>
            <Merge></Merge>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AllProduct;
