import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getUserById, updatePercentage, updateUserList } from "../../state/actions/UserActions";
import { useStateValue } from "../../state/store";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";

const EditUser = ({ idUser, setOpen, refreshList }) => {
  const classes = makeStyles();
  const classesMain = useStylesMain();
  const [{ }, dispatch] = useStateValue();
  const [socio, setSocio] = useState(false);

  const [usuario, setUsuario] = useState({
    id: idUser,
    name: "",
    lastName: "",
    email: "",
    password: "",
    username: "",
    code: "",
    bank: "",
    phone: "",
    group: 0,
    rolName: ""
  });

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    setUsuario((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getUsuarioIdAsync = async () => {
      const response = await getUserById(idUser);
      if ("Socio" === response.data.rolName) {
        setSocio(true);
      } else {
        setSocio(false);
      }
      setUsuario(response.data);
    };

    getUsuarioIdAsync();
  }, [idUser]);

  const actualizarRoleUsuario = async (e) => {
    e.preventDefault();
    const response = await updateUserList(idUser, usuario);

    if (response.status === 200) {
      await updatePercentage(usuario.group);
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "Se actualizo correctamente",
        },
      });
    } else {
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "No es posible actualizar",
        },
      });
    }
    setOpen(false);
    refreshList();
  };
  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item lg={12} sm={12}>
          <Typography variant="h4" className={classesMain.text_title}>
            Editar Usuario
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <TextField
              label="Rol"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              value={usuario.rolName}
              name="rolName"
              disabled
            />
            {socio && (
              <>
                <TextField
                  label="Codigo"
                  variant="filled"
                  fullWidth
                  className={classes.gridmb}
                  name="code"
                  value={usuario.code}
                  onChange={handleChangeUser}
                />
                <TextField
                  label="Cuenta banco"
                  variant="filled"
                  fullWidth
                  className={classes.gridmb}
                  name="bank"
                  value={usuario.bank}
                  onChange={handleChangeUser}
                />
              </>
            )}
            {socio && (
              <TextField
                label="Grupo"
                variant="filled"
                fullWidth
                className={classes.gridmb}
                name="group"
                value={usuario.group}
                onChange={handleChangeUser}
              />
            )}
            <TextField
              label="Nombre"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              name="name"
              value={usuario.name}
              onChange={handleChangeUser}
            />
            <TextField
              label="Apellidos"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              name="lastName"
              value={usuario.lastName}
              onChange={handleChangeUser}
            />
            <TextField
              label="Correo Electronico"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              value={usuario.email}
              name="email"
              onChange={handleChangeUser}
            />
            <TextField
              label="Telefono"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              value={usuario.phone}
              name="phone"
              onChange={handleChangeUser}
            />
            <TextField
              label="Username"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              value={usuario.username}
              name="username"
              onChange={handleChangeUser}
            />
            <TextField
              label="Password"
              variant="filled"
              fullWidth
              className={classes.gridmb}
              name="password"
              onChange={handleChangeUser}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={actualizarRoleUsuario}
            >
              Actualizar
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditUser;
