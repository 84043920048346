import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import {
  getAllDrivers,
  getAllProducts,
  getAllProviders,
  getDrivers,
  registerBuys,
} from "../../state/actions/ShoppingActions";
import { Autocomplete } from "@material-ui/lab";
import {
  getAllAssociate,
  getAllTractors,
  registerTractorAssociate,
} from "../../state/actions/TractorActions";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const AddShopping = ({ setOpen, refreshList }) => {
  const [inputProduct, setInputProduct] = useState(null);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [price, setPrice] = useState(0.0);
  const [inputDriver, setInputDriver] = useState(null);
  const [products, setProducts] = useState({
    data: [],
  });

  const [drivers, setDrivers] = useState({
    data: [],
  });

  useEffect(() => {
    const getListProducts = async () => {
      const response = await getAllTractors();
      setProducts(response.data);
    };

    getListProducts();

    const getListDrivers = async () => {
      const response = await getAllAssociate();
      setDrivers(response.data);
    };

    getListDrivers();
  }, []);

  const [producto, setProducto] = useState({
    associateId: 0,
    dateWork: new Date(),
    tractorId: 0,
    work: "",
    price: 0.0,
  });

  const guardarProducto = async () => {
    producto.associateId = inputDriver.id;
    producto.tractorId = inputProduct.id;
    producto.dateWork = dateFrom;

    registerTractorAssociate(producto).then((response) => {
      refreshList();
    });

    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProducto((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangePrice = (e) => {
    setPrice(e.target.value);
    const valueData = parseFloat(e.target.value);
    if (valueData > 0) {
      producto.total = producto.quantity * valueData;
    } else {
      producto.total = producto.quantity;
    }
  };
  const classesMain = useStylesMain();

  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={8} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR TRABAJO DEL TRACTOR
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <Autocomplete
              options={products}
              getOptionLabel={(option) =>
                option.driver + " " + "(" + option.plate + ")"
              }
              id="controlled-demo"
              value={inputProduct}
              onChange={(event, newValue) => {
                setInputProduct(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tractores"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>

            <Autocomplete
              options={drivers}
              getOptionLabel={(option) =>
                option.associate + " " + "(" + option.code + ")"
              }
              id="controlled-demo"
              value={inputDriver}
              onChange={(event, newValue) => {
                setInputDriver(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Socios"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateFrom}
                onChange={setDateFrom}
                margin="normal"
                id="fecha-publicacion-id"
                label="Fecha de trabajo"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <br></br>

            <TextField
              label="Detalle Trabajo"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="work"
              value={producto.work}
              onChange={handleChange}
            />

            <TextField
              label="Precio"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="price"
              value={producto.price}
              onChange={handleChange}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={guardarProducto}
            >
              AGREGAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddShopping;
