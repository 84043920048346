import AxiosRequest from "../axios/AxiosRequest";
import axios from 'axios';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

//
export const getProviderById = (id) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/product/provider/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}

export const getDriverById = (id) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/product/driver/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}

//
export const deleteProviderList = (id) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post(`/api/product/deleteUser/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response)
      });
  })
}

export const deleteDriverList = (id) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post(`/api/product/deleteDriver/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response)
      });
  })
}

//
export const updateProviderList = (id, user) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.put(`/api/product/updateList/${id}`, user)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response)
      });
  })
}

export const updateDriverList = (id, user) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.put(`/api/product/updateDriver/${id}`, user)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response)
      });
  })
}

//
export const getProviders = (request) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/product/pagination?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`)
      .then(response => {
          resolve(response);
      })
      .catch( error => {
          resolve(error.response);
      });
  });
}

export const getDrivers = (request) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/product/drivers?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`)
      .then(response => {
          resolve(response);
      })
      .catch( error => {
          resolve(error.response);
      });
  });
}

//
export const registerProvider = (usuario) => {
  return new Promise( (resolve, eject) =>{
    AxiosRequest.post("/api/product/provider", usuario).then( response => {         
          
          resolve(response);
      })
      .catch((error)=> {
          resolve(error.response);
      })
  });
}

export const registerDriver = (usuario) => {
  return new Promise( (resolve, eject) =>{
    AxiosRequest.post("/api/product/driver", usuario).then( response => {         
          
          resolve(response);
      })
      .catch((error)=> {
          resolve(error.response);
      })
  });
}
/* alll providers */
export const getProductBuys = (request) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/product/buys?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`).then( response =>{
          resolve(response);
      });
  })    
};

export const getProductBuysConfirm = (request) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/product/buysConfirm?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`).then( response =>{
          resolve(response);
      });
  })    
};

export const registerBuys = async (producto)=>{
 
  
  return new Promise((resolve, eject)=>{
    AxiosRequest.post("/api/product/buys/register", producto)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      });
  });

}


export const getAllProducts = () => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly('/api/product/products')
      .then( response =>{
          resolve(response);
      });
  })    
};

export const getAllProviders = () => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly('/api/product/providers')
      .then( response =>{
          resolve(response);
      });
  })    
};


export const getAllDrivers = () => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly('/api/product/driversList')
      .then( response =>{
          resolve(response);
      });
  })    
};

export const updateConfirm = async (id, buy) => {
  return new Promise( (resolve,eject) => {

    AxiosRequest.put(`/api/product/confirm/${id}`, buy)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}

export const getProductPurchased = (request) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/product/purchased?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`).then( response =>{
          resolve(response);
      });
  })    
};

export const deleteShopping = async (id) => { 

  return new Promise( (resolve,eject) => {

    AxiosRequest.delete(`/api/product/deleteShopping/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}