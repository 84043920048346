import {
  Button,
  Container,
  DialogContentText,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import { Pagination } from "@material-ui/lab";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditDriver from "./EditDriver";
import RegisterDriver from "./RegisterDriver";
import { getTractors, deleteDriverList } from "../../state/actions/TractorActions";

const Tractors = () => {
  const classesMain = useStylesMain();

  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idUser, setIdUser] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [requestUsuarios, setRequestUsuarios] = useState({
    pageIndex: 1,
    pageSize: 5,
    search: "",
  });

  const [paginadorUsuarios, setPaginadorUsuarios] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    PageCount: 0,
    data: [],
  });

  const handleChange = (event, value) => {
    setRequestUsuarios((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  useEffect(() => {
    const getListaUsuarios = async () => {
      const response = await getTractors(requestUsuarios);
      setPaginadorUsuarios(response.data);
    };

    getListaUsuarios();
  }, [requestUsuarios]);

  const editaUsuario = (id) => {
    setOpen(true);
    setIdUser(id);
  };

  const removerUsuario = (id) => {
    setOpenDelete(true);
    setIdUser(id);
  };

  const refreshList = () => {
    const getListaUsuarios = async () => {
      const response = await getTractors(requestUsuarios);
      setPaginadorUsuarios(response.data);
    };

    getListaUsuarios();
  };

  const agregarUsuario = () => {
    setOpenAdd(true);
  };

  const deleteUser = () => {
    deleteDriverList(idUser).then((response) => {
      const getListaUsuarios = async () => {
        const responsed = await getTractors(requestUsuarios);
        setPaginadorUsuarios(responsed.data);
      };

      getListaUsuarios();
    });

    setOpenDelete(false);
  };
  return (
    <Container className={classesMain.containermt}>
      <Grid container>
        <Grid item lg={6} sm={6} xs={12}>
          <Typography variant="h4" className={classesMain.text_title}>
            TRACTORES
          </Typography>
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classesMain.buttonAgregar}
            onClick={agregarUsuario}
          >
            <Icon>add</Icon>
            NUEVO TRACTOR
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CONDUCTOR</TableCell>
              <TableCell>LICENCIA</TableCell>
              <TableCell>PLACA</TableCell>
              <TableCell>TELEFONO</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginadorUsuarios.data.map((usuario) => (
              <TableRow key={usuario.id}>
                <TableCell>{usuario.driver}</TableCell>
                <TableCell>{usuario.license}</TableCell>
                <TableCell>{usuario.plate}</TableCell>
                <TableCell>{usuario.phone}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => editaUsuario(usuario.id)}
                  >
                    <Icon>edit</Icon>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => removerUsuario(usuario.id)}
                  >
                    <Icon>delete</Icon>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginadorUsuarios.pageCount}
        page={paginadorUsuarios.pageIndex}
        onChange={handleChange}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <EditDriver
          idUser={idUser}
          setOpen={setOpen}
          refreshList={refreshList}
        ></EditDriver>

        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAdd}
        onClose={handleCloseAdd}
        aria-labelledby="form-dialog-title"
      >
        <RegisterDriver
          setOpenAdd={setOpenAdd}
          refreshList={refreshList}
        ></RegisterDriver>

        <DialogActions>
          <Button onClick={handleCloseAdd} fullWidth color="secondary" variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar conductor</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de eliminar el conductor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteUser} color="primary" variant="contained">
            Eliminar
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="primary"
            autoFocus
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Tractors;
