
import AxiosRequest from "../axios/AxiosRequest";
import axios from "axios";

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const getRoles = (request) => {
    return new Promise((resolve, eject) => {
        AxiosRequest.getOnly(
            `/api/role/pagination?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`
        )
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error.response);
            });
    });
};

export const updateRole = (roleId, role) => {
    return new Promise((resolve, eject) => {
        AxiosRequest.put(`/api/role/${roleId}`, role)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error.response);
            });
    });
};

export const registerRole = (role) => {
    return new Promise((resolve, eject) => {
        instancia
            .post("/api/role/create", role)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error.response);
            });
    });
};

export const getRoleById = (roleId) => {
    return new Promise((resolve, eject) => {
      AxiosRequest.getOnly(`/api/role/account/${roleId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(error.response);
        });
    });
  };

  export const getAllRoles = () => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly('/api/role/all')
        .then( response =>{
            resolve(response);
        });
    })    
  };