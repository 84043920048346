import AxiosRequest from "../axios/AxiosRequest";


export const getAllCategories = () => {
  return new Promise( (resolve, eject) => {
      AxiosRequest.getOnly('/api/category')
      .then( response =>{
          resolve(response);
      });
  })    
};

export const getCategory =  id => {
  return new Promise( (resolve, eject) => {
      AxiosRequest.getOnly(`/api/category/${id}`)
      .then( response => {
          resolve(response);
      })
      .catch( error => {
          resolve(error.response);
      });
  });
}
