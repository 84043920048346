import { Button, Container, Divider, Typography, Grid, TextField, } from "@material-ui/core";
import { getBuyByProductPdf } from "../../state/actions/ProductActions";
import useStylesMain from "../../styles/useStylesMain";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { getAllProducts } from "../../state/actions/ShoppingActions";

const BuyProduct = () => {
  const classes = useStylesMain();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const [inputProduct, setInputProduct] = useState(null);
  const [products, setProducts] = useState({
    data: [],
  });

  useEffect(() => {
    const getListProducts = async () => {
      const response = await getAllProducts();
      setProducts(response.data);
    };
    getListProducts();
  }, []);


  const addSelection = async () => {
    const item = {
      dateFrom: new Date(dateFrom).toLocaleDateString('en-ZA'),
      dateTo: new Date(dateTo).toLocaleDateString('en-ZA'),
      productId: inputProduct.productId,
    };

    const listExcel = async (dataItem) => {
      const res = await getBuyByProductPdf(dataItem);
      const file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };
    listExcel(item);
  };



  return (
    <>
      <Container className={classes.containermt}>

        <Typography variant="h6" className={classes.text_title}>
          COMPRA DE PRODUCTO POR FECHA
        </Typography>
        <br></br>
        <Grid container spacing={3}>
          <Grid item md>
            <Autocomplete
              options={products}
              getOptionLabel={(option) =>
                option.name + " " + "(" + option.marca + ")"
              }
              id="controlled-demo"
              value={inputProduct}
              onChange={(event, newValue) => {
                setInputProduct(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Productos"
                  variant="outlined"

                />
              )}
            />
          </Grid>
          <Grid item>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateFrom}
                onChange={setDateFrom}
                id="fecha-publicacion-id"
                label="Desde fecha"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateTo}
                onChange={setDateTo}
                id="fecha-publicacion-id"
                label="Hasta fecha"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={addSelection}>
              GENERAR EXCEL
            </Button>
          </Grid>

        </Grid>

        <br></br>
        <Divider className={classes.divider} />
      </Container>
    </>
  );
};

export default BuyProduct;
