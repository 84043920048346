import AxiosRequest from "../axios/AxiosRequest";
import axios from "axios";
//import { uploadImage } from '../firebase';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const getPil = (request) => {
  return new Promise((resolve, eject) => {
    AxiosRequest
      .getOnly(
        `/api/pil?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`
      )
      .then((response) => {
        resolve(response);
      });
  });
};

export const registerPil = async (pil) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post("/api/pil", pil)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const editPil = async (pil) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post("/api/pil/editPil", pil)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const deleteContribution = async (contri) => {

  return new Promise( (resolve,eject) => {

    AxiosRequest.post("/api/pil/deleteContribution", contri)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}

export const getPilOnly = async (id) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/pil/getPil/${id}`)
    .then( response => {
        resolve(response);
    })
    .catch( error => {
        resolve(error.response);
    });
});
};

export const deletePil = async (id) => {

  return new Promise( (resolve,eject) => {
    AxiosRequest.delete(`/api/pil/deletePil/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}

export const deleteContributionAssocaite = async (id) => {

  return new Promise( (resolve,eject) => {
    AxiosRequest.delete(`/api/pil/deleteContribution/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}

export const getEntranceOnly = async (id) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/pil/getEntrance/${id}`)
    .then( response => {
        resolve(response);
    })
    .catch( error => {
        resolve(error.response);
    });
});
};

export const editEntrance = async (entrance) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post("/api/pil/editEntrance", entrance)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const deleteEntrance = async (id) => {

  return new Promise( (resolve,eject) => {
    AxiosRequest.delete(`/api/pil/deleteEntrance/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}

export const getPilByCode =  (code,id) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/pil/${code}/${id}`)
      .then( response => {
          resolve(response);
      })
      .catch( error => {
          resolve(error.response);
      });
  });
}

export const getContribution = (request) => {
  return new Promise((resolve, eject) => {
    AxiosRequest
      .getOnly(
        `/api/pil/contribution?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`
      )
      .then((response) => {
        resolve(response);
      });
  });
};

export const getContributionDelete = (request) => {
  return new Promise((resolve, eject) => {
    AxiosRequest
      .getOnly(
        `/api/pil/contributionDelete?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`
      )
      .then((response) => {
        resolve(response);
      });
  });
};

export const getContributionAssociate = (request) => {
  return new Promise((resolve, eject) => {
    AxiosRequest
      .getOnly(
        `/api/pil/associate?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}&date=${request.date}`
      )
      .then((response) => {
        resolve(response);
      });
  });
};

export const registerContribution = async (pil) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post("/api/pil/contribution", pil)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const registerContributionAssociate = async (pil) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post("/api/pil/associate", pil)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getAllContribution = () => {
  return new Promise( (resolve, eject) => {
      AxiosRequest.getOnly('/api/pil/all')
      .then( response =>{
          resolve(response);
      });
  })    
};
