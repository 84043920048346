import {
  Avatar,
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";
import ImageUploader from "react-images-upload";
import { useStateValue } from "../../state/store";
import { updateUsuario } from "../../state/actions/UserActions";

const Perfil = (props) => {
  const classes = makeStyles();
  const classesMain = useStylesMain();
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [usuario, setUsuario] = useState({
    name: "",
    lastName:"",
    email: "",
    password: "",
    username: "",
    imagenPerfil: null,
    fotoUrl: "",
  });

  useEffect(() => {
    setUsuario(sesionUsuario.usuario);
    setUsuario((anterior) => ({
      ...anterior,
      //fotoUrl: sesionUsuario.usuario.imagenPerfil,
      imagenPerfil : null
    }));
  }, []);

  const verDetalles = () => {
    const id = "1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed";
    props.history.push("/ordenCompra/" + id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUsuario(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault(); 
    const id = sesionUsuario.usuario.id; 
    updateUsuario(id, usuario, dispatch).then((response) => {
      console.log("Usuario actualizado");
    }).catch((error) => {
      console.error("Error al actualizar el usuario", error);
    });
  };
  

  return (
    <Container className={classesMain.containermt}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            PERFIL DE USUARIO
          </Typography>
          <form onSubmit={(e) => e.preventDefault()} className={classesMain.form}>
            <ImageUploader
              withIcon={false}
              buttonStyles={{
                borderRadius: "50%",
                padding: 10,
                margin: 0,
                position: "absolute",
                bottom: 15,
                left: 15,
              }}
              className={classes.imageUploader}
              buttonText={<Icon>add_a_photo</Icon>}
              label={
                <Avatar
                  alt="mi perfil"
                  className={classes.avatarPerfil}
                  src="https://tottope.vteximg.com.br/arquivos/ids/167188-1000-1000/PILIGRAM-H-1810-V07_A.png?v=636723781789170000"
                />
              }
              imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
              maxFileSize={5242880}
            />
            <TextField
              label="Nombre"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              value={usuario.name}
              name="name"
              onChange={handleInputChange} 
            />
            <TextField
              label="Apellidos"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              value={usuario.lastName}
              name="lastName"
              onChange={handleInputChange} 
            />
            <TextField
              label="Correo Electronico"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb} 
              value={usuario.email}
              name="email"
              onChange={handleInputChange} 
            />
            <Divider className={classes.divideruser} />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              value={usuario.password}
              name="password"
              className={classesMain.gridmb}
              onChange={handleInputChange} 
            />
            <TextField
              label="Confirmar Password"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              value={usuario.password}
              name="password"
              onChange={handleInputChange} 
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              ACTUALIZAR
            </Button>
          </form>
        </Grid>        
      </Grid>
    </Container>
  );
};

export default Perfil;
