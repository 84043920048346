import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import { savePrice } from "../../state/actions/ProductActions";
import { useStateValue } from "../../state/store";

const AddPrice = ({ setOpenPrice, idProduct, refreshList }) => {
    const [producto, setProducto] = useState({
        priceUnit: 0,
        productId: idProduct,
    });

    const [{ }, dispatch] = useStateValue();

    const handleChangeUnit = (e) => {
        const priceUnit = e.target.value;
        setProducto((prev) => ({
            ...prev,
            priceUnit,
        }));
    };

    const savePriceData = async () => {
        try {
            await savePrice(producto);
            refreshList();
            setOpenPrice(false);
            dispatch({
                type: "OPEN_SNACKBAR",
                openMensaje: {
                    open: true,
                    mensaje: "Se establecio el precio correctamente",
                },
            });

        } catch (error) {
            console.error("Error saving price:", error);
            dispatch({
                type: "OPEN_SNACKBAR",
                openMensaje: {
                    open: true,
                    mensaje: "No se pudo establecer el precio correctamente",
                },
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        savePriceData();
    };

    const classesMain = useStylesMain();

    return (
        <Container className={classesMain.containermt}>
            <Grid container justify="center">
                <Grid item sm={12} xs={12}>
                    <Typography variant="h5" className={classesMain.text_title}>
                        ESTABLECER PRECIO DEL PRODUCTO
                    </Typography>
                    <form onSubmit={handleSubmit} className={classesMain.form}>
                        <TextField
                            label="Precio unidad"
                            variant="outlined"
                            fullWidth
                            className={classesMain.gridmb}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="unit"
                            value={producto.priceUnit}
                            onChange={handleChangeUnit}
                        />
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            ESTABLECER
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AddPrice;
