import {
  Button,
  Container,
  Divider,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { getSalesPayConfirm } from "../../state/actions/ProductActions";
import useStylesMain from "../../styles/useStylesMain";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useState } from "react";

const PaysConfirm = () => {
  const classes = useStylesMain();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [code, setCode] = useState("");
  const [payConfirm, setPayConfirm] = useState([]);
  const [associate, setAssociate] = useState(null);

  const addSelection = async () => {
    const item = {
      code: code,
      dateFrom: new Date(dateFrom).toLocaleString(),
      dateTo: new Date(dateTo).toLocaleString(),
    };

    const getListPayConfirm = async () => {
      const response = await getSalesPayConfirm(item);
      setPayConfirm(response.data.payConfirms);
      setAssociate(response.data.payConfirmAssociate);
    };

    getListPayConfirm();
  };

  const handleChangeCode = (e) => {
    setCode(e.target.value);
    if(e.target.value == ""){
      setAssociate(null);
      //setPayConfirm([]);
    }
  };

  return (
    <>
      <Container className={classes.containermt}>
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.text_title}>
          Ingresar el codigo de socio y seleccione desde que fecha y hasta que
          fecha desea ver los cobros.
        </Typography>
        <TextField
          label="Codigo de socio"
          variant="outlined"
          className={classes.gridmb}
          InputLabelProps={{
            shrink: true,
          }}
          name="code"
          value={code}
          onChange={handleChangeCode}
        />
        {null != associate &&
         <>
         <TextField
          label="Nombre"
          variant="outlined"
          className={classes.gridmb}
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          name="name"
          value={associate.name + " "+ associate.lastName}
        />

        <TextField
          label="Grupo"
          variant="outlined"
          className={classes.gridmb}
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          name="name"
          value={associate.group}
        />
        </>
      }
        <br></br>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={dateFrom}
            onChange={setDateFrom}
            margin="normal"
            id="fecha-publicacion-id"
            label="Seleccione inicio"
            format="dd/MM/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={dateTo}
            onChange={setDateTo}
            margin="normal"
            id="fecha-publicacion-id"
            label="Seleccione fin"
            format="dd/MM/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        <br></br>

        <Button variant="contained" color="primary" onClick={addSelection}>
          BUSCAR COBROS
        </Button>
        <br></br>
        <Divider className={classes.divider} />

        <Typography variant="h4" className={classes.text_title}>
          COBROS
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Monto</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Pagado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payConfirm.map((item,index) => (
                <TableRow key={index}>
                  <TableCell>{item.item}</TableCell>
                  <TableCell>{item.price}Bs</TableCell>
                  <TableCell>{item.datePay}</TableCell>
                  <TableCell>
                    <Icon className={classes.iconDelivered}>check</Icon>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default PaysConfirm;
