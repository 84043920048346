import { Avatar, CardMedia } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getDocument } from "../../state/actions/ImageAction";
import makeStyles from "../Product/styles";

const DocumentBuy = ({ id, price }) => {
  const [image, setImage] = useState("");
  useEffect(() => {
    const getData = async () => {
      const response = await getDocument(id);
      let imagenDefault =
        "data:image/" +
        response.data.extension +
        ";base64," +
        response.data.data;
      setImage(imagenDefault);
    };

    getData();
  }, [id]);

  const classes = makeStyles();
  return (
    <CardMedia className={classes.media} title="mi producto" image={image}>
      <Avatar variant="square" className={classes.price}>
        {price}Bs
      </Avatar>
    </CardMedia>
  );
};

export default DocumentBuy;
