import { createMuiTheme, makeStyles } from "@material-ui/core";

const theme = createMuiTheme();

const useStylesMain = makeStyles({
  containermt: {
    margin: 5,
    

  },
  card: {
    padding: 15,
  },
  avatar: {
    backgroundColor: "#0f80aa",
    width: 80,
    height: 80,
  },
  icon: {
    fontSize: 60,
  },
  form: {
    marginTop: 10,
    marginBottom: 10,
  },
  gridmb: {
    marginBottom: 10,
  },
  link: {
    marginTop: 8,
    fontSize: "1.1rem",
    fontFamily: "Roboto",
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  text_title: {
    fontWeight: 500,
    color: "#494949",
    textAlign:"center"
    // marginBottom: -25,
  },
  table: {
    border: "1px solid #e0e0e0",
  },
  iconNotDelivered: {
    color: "red",
    fontWeight: 900,
  },
  iconDelivered: {
    color: "green",
    fontWeight: 900,
  },
  buttonAgregar: {
    float: "right",
  },
  checkbox: {
    display: "block",
    padding: 0,
    marginTop: 5,
    marginBottom: 5,
  },
  text_title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  gridmb: {
    marginBottom: theme.spacing(2),
  },
  rootReport: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  cardReport: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: '10px',
    border: '1px solid #ddd', // Add border
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add box shadow
  },
});

export default useStylesMain;
