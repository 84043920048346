import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import useStylesMain from '../../styles/useStylesMain';
import Associate from './Associate';
import Contribution from './Contribution';


const AllAssociate = () => {
  const classes = useStylesMain();


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item >
          <Paper  className={classes.card}>
            <Associate></Associate>
          </Paper>
        </Grid>
        <Grid item >
          <Paper  className={classes.card}>
            <Contribution></Contribution>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AllAssociate;
