import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getRoleById, updateRole } from "../../state/actions/RoleActions";
import { useStateValue } from "../../state/store";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";

const EditRole = ({ idRole, setOpen, refreshList }) => {
    const classes = makeStyles();
    const classesMain = useStylesMain();
    const [{}, dispatch] = useStateValue();

    const [role, setRole] = useState({
        id: idRole,
        rolName: "",
    });

    const handleChangeUser = (e) => {
        const { name, value } = e.target;
        setRole((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        const getUsuarioIdAsync = async () => {
            const response = await getRoleById(idRole);
            setRole(response.data);
        };

        getUsuarioIdAsync();
    }, [idRole]);

    const actualizarRole = async (e) => {
        e.preventDefault();
        const response = await updateRole(idRole, role);

        if (response.status === 200) {
            dispatch({
                type: "OPEN_SNACKBAR",
                openMensaje: {
                    open: true,
                    mensaje: "Se actualizo correctamente",
                },
            });
        } else {
            dispatch({
                type: "OPEN_SNACKBAR",
                openMensaje: {
                    open: true,
                    mensaje: "No es posible actualizar",
                },
            });
        }
        setOpen(false);
        refreshList();
    };
    return (
        <Container className={classesMain.containermt}>
            <Grid container justify="center">
                <Grid item lg={12} sm={12}>
                    <Typography variant="h4" className={classesMain.text_title}>
                        Editar Rol
                    </Typography>
                    <form
                        onSubmit={(e) => e.preventDefault()}
                        className={classesMain.form}
                    >

                        <TextField
                            label="Nombre"
                            variant="filled"
                            fullWidth
                            className={classes.gridmb}
                            name="rolName"
                            value={role.rolName}
                            onChange={handleChangeUser}
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={actualizarRole}
                        >
                            Actualizar
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
};

export default EditRole;
