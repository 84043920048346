import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import EditProduct from "../Product/EditProduct";
import makeStyles from "../Product/styles";
import { deleteShopping, getProductBuys } from "../../state/actions/ShoppingActions";
import AddShopping from "./AddShopping";
import ConfirmShopping from "./ConfirmShopping";
import { getPercentagePdf, saveReceived } from "../../state/actions/ProductActions";

const Shoppings = () => {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [idProduct, setIdProduct] = useState("");
  const [cantidad, setCantidad] = useState(0.0);
  const [total, setTotal] = useState(0.0);
  const [purchase, setPurchase] = useState(0.0);
  const [group, setGroup] = useState("");
  const classes = makeStyles();
  const classesMain = useStylesMain();
  const [openMerge, setOpenMerge] = useState(false);
  const [quantity, setQuantity] = useState(0);

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleCloseAdd = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const [requestProductos, setRequestProductos] = useState({
    pageIndex: 1,
    pageSize: 10,
    search: "",
  });

  const [paginadorProductos, setPaginadorProductos] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    pageCount: 0,
    data: [],
  });

  const handleChange = (event, value) => {
    setRequestProductos((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  useEffect(() => {
    const getListaCompras = async () => {
      const response = await getProductBuys(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaCompras();
  }, [requestProductos]);

  const agregarProducto = () => {
    setOpen(true);
  };

  const addMerge = (id, realQuantity) => {
    setOpenMerge(true);
    setIdProduct(id);
    setCantidad(realQuantity);

  };

  const refreshList = () => {
    const getListaCompras = async () => {
      const response = await getProductBuys(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaCompras();
  };

  const removerProducto = (id) => {
    setOpenDelete(true);
    setIdProduct(id);
  };

  const deleteProduct = () => {
    deleteShopping(idProduct).then((response) => {
      const getListaCompras = async () => {
        const responsed = await getProductBuys(requestProductos);
        setPaginadorProductos(responsed.data);
      };

      getListaCompras();
    });

    setOpenDelete(false);
  };

  const confirmProduct = (id, quantity, total, group) => {
    setPurchase(total / quantity);
    setCantidad(quantity);
    setOpenConfirm(true);
    setIdProduct(id);
    setGroup(group);
    setTotal(total);
  };

  const addSelection = (id, gro, productId) => {
    const groups = gro.split(',');
    const handlePdfForGroup = async (group) => {
      const printer = {
        group: group,
        id: productId,
        provider: id
      };

      const res = await getPercentagePdf(printer);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };

    groups.forEach(group => {
      handlePdfForGroup(group);
    });
  };

  const handleCloseMerge = () => {
    setOpenMerge(false);
  };

  const handleChangeQuantity = (e) => {
    const item = e.target.value;
    setQuantity(item);
  };

  const saveMergeData = async () => {
    const receivedClass = {
      received: cantidad - quantity,
      id: idProduct,
    };
    saveReceived(receivedClass).then((response) => {
      refreshList();
    });

    setOpenMerge(false);
  };

  return (
    <Container className={classesMain.containermt}>
      <Grid container>
        <Grid item lg={6} sm={10} xs={12}>
          <Typography
            component={"span"}
            variant="h4"
            className={classesMain.text_title}
          >
            PEDIDOS
          </Typography>
        </Grid>
        <Grid item lg={6} sm={10} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonAgregar}
            onClick={agregarProducto}
          >
            <Icon>add</Icon>
            NUEVO PEDIDO
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>              
              <TableCell>PRODUCTO</TableCell>
              <TableCell>FECHA COMPRA</TableCell>
              <TableCell>CANTIDAD</TableCell>
              <TableCell>TOTAL</TableCell>
              <TableCell>ADELANTO</TableCell>
              <TableCell>SALDO</TableCell>
              <TableCell>CANTIDA DISPONIBLE</TableCell>
              <TableCell>DETALLE</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginadorProductos.data.map((producto) => (
              <TableRow key={producto.id}>                
                <TableCell>{producto.product}</TableCell>
                <TableCell>{new Date(producto.date).toLocaleString()}</TableCell>
                <TableCell>{producto.quantity}</TableCell>
                <TableCell>{producto.total + " Bs"}</TableCell>
                <TableCell>{producto.advance + " Bs"}</TableCell>
                <TableCell>{producto.residue + " Bs"}</TableCell>
                <TableCell>{producto.received > 0 ? producto.received : ""}</TableCell>
                <TableCell>{producto.details}</TableCell>

                <TableCell>
                  <Button
                    onClick={() => removerProducto(producto.id)}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      confirmProduct(
                        producto.id,
                        producto.quantity,
                        producto.total,
                        producto.group
                      )
                    }
                  >
                    <Icon>check</Icon>
                  </Button>

                  {producto.isKg && <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      addMerge(
                        producto.id,
                        producto.quantity
                      )
                    }
                  >
                    <Icon>remove</Icon>
                  </Button>
                  }


                  {producto.isKg && <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      addSelection(
                        producto.id,
                        producto.group,
                        producto.productId
                      )
                    }
                  >
                    <Icon>print</Icon>
                  </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginadorProductos.pageCount}
        page={paginadorProductos.pageIndex}
        onChange={handleChange}
      />

      <Dialog
        open={openMerge}
        onClose={handleCloseMerge}
        aria-labelledby="form-dialog-title"
      >
        <Container className={classesMain.containermt}>
          <Grid container justify="center">
            <Grid item sm={12} xs={12}>
              <Typography variant="h6" className={classesMain.text_title}>
                AGREGAR PERDIDA ESTIMADA
              </Typography>
              <form
                onSubmit={(e) => e.preventDefault()}
                className={classesMain.form}
              >
                <TextField
                  label="Perdida estimada"
                  variant="outlined"
                  fullWidth
                  className={classesMain.gridmb}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="quantity"
                  value={quantity}
                  onChange={handleChangeQuantity}
                />
                <Button variant="contained" color="primary" fullWidth onClick={saveMergeData}>
                  AGREGAR
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>

        <DialogActions>
          <Button
            onClick={handleCloseMerge}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleCloseAdd}
        aria-labelledby="form-dialog-title"
      >
        <AddShopping setOpen={setOpen} refreshList={refreshList}></AddShopping>

        <DialogActions>
          <Button onClick={handleCloseAdd} fullWidth color="secondary" variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="form-dialog-title"
      >
        <EditProduct
          setOpenUpdate={setOpenUpdate}
          refreshList={refreshList}
          idProduct={idProduct}
        ></EditProduct>

        <DialogActions>
          <Button
            onClick={handleCloseUpdate}
            color="primary"
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar pedido</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de eliminar el pedido?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteProduct} color="primary" variant="contained">
            Eliminar
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="primary"
            autoFocus
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar compra</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de confirmar que recibio el pedido del producto?
          </DialogContentText>
        </DialogContent>
        <ConfirmShopping
          setOpenConfirm={setOpenConfirm}
          refreshList={refreshList}
          idProduct={idProduct}
          total={total}
          cantidad={cantidad}
          grupo={group}
          purchases={purchase}
        ></ConfirmShopping>
      </Dialog>
    </Container>
  );
};

export default Shoppings;
