import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  imageUploader: {
    padding: 0,
    margin: "-25px auto 15px",
    width: 0,
    
  },
  avatarPerfil: {
    width: 130,
    height: 130,
    backgroundColor: "#0f80aa",
  },
  divideruser: {
    marginTop: 12,
    marginBottom: 12,
  },
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  linkAppBarMobile: {
    display: "inline-flex",
    alignItems: "center",
    color: "inherit",
    textDecoration: "none",
    width: "100%",
    padding: "8px 16px 8px 16px",
  },
  listItem: {
    padding: 0,
  },
  listItemIcon : {
    minWidth: 35
},
}));
