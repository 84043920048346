import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { deleteOrder, getSales } from "../../state/actions/ProductActions";
import useStylesMain from "../../styles/useStylesMain";
import OrderUser from "./OrderUser";

const Orders = () => {
  const classes = useStylesMain();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idAssociate, setIdAssociate] = useState("");
  const [itemData, setItemData] = useState({
    id: 0,
    name: "",
    lastName: "",
    code: "",
    price: 0,
  });

  const [paginadorUsuarios, setPaginadorUsuarios] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    PageCount: 0,
    data: [],
  });

  const [requestSales, setRequestSales] = useState({
    pageIndex: 1,
    pageSize: 10,
    search: "",
  });

  useEffect(() => {
    const getListaProductos = async () => {
      const response = await getSales(requestSales);
      setPaginadorUsuarios(response.data);
    };

    getListaProductos();
  }, [requestSales]);

  const verDetalle = (item) => {

    setItemData((anterior) => ({
      ...anterior,
      id: item.id,
      name: item.name,
      lastName: item.lastName,
      code: item.code,
      price: item.price,
      group:item.group
    }));
    setOpen(true);
  };

  const handleChange = (event, value) => {
    setRequestSales((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  const closeDialog = () => {
    const getListaProductos = async () => {
      const response = await getSales(requestSales);
      setPaginadorUsuarios(response.data);
    };

    getListaProductos();
    setOpen(false);
  };

  const deleteOrderDialog = (id) => {
    setOpenDelete(true);
    setIdAssociate(id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const deleteProduct = () => {
    deleteOrder(idAssociate).then((response) => {
      const getListaProductos = async () => {
        const response = await getSales(requestSales);
        setPaginadorUsuarios(response.data);
      };
  
      getListaProductos();
    });

    setOpenDelete(false);
  };

  return (
    <>
      <Container className={classes.containermt}>
        <Typography variant="h4" className={classes.text_title}>
          PEDIDOS
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Codigo</TableCell>
                <TableCell>Precio</TableCell>
                {/* <TableCell>ENTREGADO</TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginadorUsuarios.data.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.lastName}</TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.price} Bs</TableCell>
                  {/* <TableCell>
                    <Icon className={classes.iconDelivered}>clear</Icon>
                  </TableCell> */}
                  <TableCell>
                  <Button
                    onClick={() => deleteOrderDialog(item.id)}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => verDetalle(item)}
                    >
                      ENTREGAR
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          count={paginadorUsuarios.pageCount}
          page={paginadorUsuarios.pageIndex}
          onChange={handleChange}
        />
      </Container>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <OrderUser itemData={itemData} setOpen={setOpen} setRequestSales={setRequestSales}></OrderUser>

        <DialogActions>
          <Button onClick={closeDialog} color="secondary" fullWidth variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ELIMINAR PEDIDO</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de eliminar el pedido?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteProduct} color="primary" fullWidth variant="contained">
            Eliminar
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="secondary"
            fullWidth
            autoFocus
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Orders;
