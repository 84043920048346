import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import EditProduct from "../Product/EditProduct";
import makeStyles from "../Product/styles";
import {
  deleteShopping,
  getProductBuys,
} from "../../state/actions/ShoppingActions";
import AddShopping from "./AddShopping";
import ConfirmShopping from "./ConfirmShopping";
import { editPaid, getAllWorks } from "../../state/actions/TractorActions";

const Works = () => {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [idProduct, setIdProduct] = useState("");
  const [cantidad, setCantidad] = useState(0.0);
  const [total, setTotal] = useState(0.0);
  const [purchase, setPurchase] = useState(0.0);
  const classes = makeStyles();
  const classesMain = useStylesMain();

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleCloseAdd = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const [requestProductos, setRequestProductos] = useState({
    pageIndex: 1,
    pageSize: 5,
    search: "",
    date: new Date().toLocaleDateString("en-ZA"),
  });

  const [paginadorProductos, setPaginadorProductos] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    pageCount: 0,
    data: [],
  });

  const handleChange = (event, value) => {
    setRequestProductos((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  useEffect(() => {
    const getListaCompras = async () => {
      const response = await getAllWorks(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaCompras();
  }, [requestProductos]);

  const agregarProducto = () => {
    setOpen(true);
  };

  const editaProducto = (id) => {
    setIdProduct(id);
    setOpenUpdate(true);
  };

  const refreshList = () => {
    const getListaCompras = async () => {
      const response = await getAllWorks(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaCompras();
  };

  const removerProducto = (id) => {
    setOpenDelete(true);
    setIdProduct(id);
  };

  const deleteProduct = () => {
    deleteShopping(idProduct).then((response) => {
      const getListaCompras = async () => {
        const responsed = await getProductBuys(requestProductos);
        setPaginadorProductos(responsed.data);
      };

      getListaCompras();
    });

    setOpenDelete(false);
  };

  const confirmProduct = (data) => {
    const pil = {
      id: data,
    };

    editPaid(pil).then((response) => {
      refreshList();
    });
  };

  const updatePaid = async (e, data) => {
    e.preventDefault();

    const pil = {
      id: data,
    };

    const response = await editPaid(pil);

    if (response.status === 200) {
      refreshList();
    }
  };

  return (
    <Container className={classesMain.containermt}>
      <Grid container>
        <Grid item lg={6} sm={10} xs={12}>
          <Typography
            component={"span"}
            variant="h4"
            className={classesMain.text_title}
          >
            Trabajos
          </Typography>
        </Grid>
        <Grid item lg={6} sm={10} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonAgregar}
            onClick={agregarProducto}
          >
            <Icon>add</Icon>
            NUEVO TRABAJO
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SOCIO</TableCell>
              <TableCell>CODIGO</TableCell>
              <TableCell>CONDUCTOR</TableCell>
              <TableCell>PLACA</TableCell>
              <TableCell>DETALLE</TableCell>
              <TableCell>PRECIO</TableCell>
              <TableCell>FECHA TRABAJO</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginadorProductos.data.map((producto) => (
              <TableRow key={producto.id}>
                <TableCell>{producto.associate}</TableCell>
                <TableCell>{producto.code}</TableCell>
                <TableCell>{producto.driver}</TableCell>
                <TableCell>{producto.plate}</TableCell>
                <TableCell>{producto.detail}</TableCell>
                <TableCell>{producto.price + " Bs"}</TableCell>
                <TableCell>
                  {new Date(producto.date).toLocaleString()}
                </TableCell>

                <TableCell>
                  <Button
                    onClick={() => removerProducto(producto.id)}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    
                    onClick={() => confirmProduct(producto.id)}
                  >
                     <Tooltip title="Poner como pagado">
                    <Icon>check</Icon>
                    </Tooltip>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginadorProductos.pageCount}
        page={paginadorProductos.pageIndex}
        onChange={handleChange}
      />

      <Dialog
        open={open}
        onClose={handleCloseAdd}
        aria-labelledby="form-dialog-title"
      >
        <AddShopping setOpen={setOpen} refreshList={refreshList}></AddShopping>

        <DialogActions>
          <Button
            onClick={handleCloseAdd}
            fullWidth
            color="secondary"
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="form-dialog-title"
      >
        <EditProduct
          setOpenUpdate={setOpenUpdate}
          refreshList={refreshList}
          idProduct={idProduct}
        ></EditProduct>

        <DialogActions>
          <Button
            onClick={handleCloseUpdate}
            color="primary"
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar trabajo</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de eliminar el trabajo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteProduct} color="primary" variant="contained">
            Eliminar
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="primary"
            autoFocus
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar compra</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de confirmar que recibio el pedido del producto?
          </DialogContentText>
        </DialogContent>
        <ConfirmShopping
          setOpenConfirm={setOpenConfirm}
          refreshList={refreshList}
          idProduct={idProduct}
          total={total}
          cantidad={cantidad}
          purchases={purchase}
        ></ConfirmShopping>
      </Dialog>
    </Container>
  );
};

export default Works;
