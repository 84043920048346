import {
  Avatar,
  Button,
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "../Product/styles";
import {
  registerPil,
  registerContribution,
} from "../../state/actions/PilActions";

const AddEntrance = ({ setOpen, refreshList }) => {
  const [pil, setPil] = useState({
    name: "",
    code: "",
    price: 0.0,
  });

  const saveMilk = async () => {
    registerContribution(pil).then((response) => {
      refreshList();
    });

    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPil((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const classes = makeStyles();
  const classesMain = useStylesMain();

  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={8} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR DETALLES
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <TextField
              label="Nombre aporte"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="name"
              value={pil.name}
              onChange={handleChange}
            />
            <TextField
              label="Codigo aporte"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="code"
              value={pil.code}
              onChange={handleChange}
            />

            <TextField
              label="Monto"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="price"
              value={pil.price}
              onChange={handleChange}
            />

            <Button variant="contained" fullWidth color="primary" onClick={saveMilk}>
              AGREGAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddEntrance;
