import React from "react";
import ReactDOM from "react-dom";
import { mainReducer } from "./components/state/reducers";
import { initialState } from "./components/state/initialState";
import { StateProvider } from "./components/state/store";
import MainApp from "./MainApp";

const divRoot = document.querySelector("#root");
ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={mainReducer}>
      <MainApp />
    </StateProvider>
  </React.StrictMode>,

  divRoot
);
