import {
  Avatar,
  Button,
  Card,
  Container,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { registerDriver, registerProvider } from "../../state/actions/ShoppingActions";
import { registerTractor } from "../../state/actions/TractorActions";
import { useStateValue } from "../../state/store";
import useStylesMain from "../../styles/useStylesMain";

const RegisterDriver = ({ setOpenAdd, refreshList }) => {
  const [user, setUser] = useState({
    driver: "",
    address: "",
    phone: 0,
    plate: "",
    license: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveUser = () => {
    registerTractor(user).then((response) => {
      refreshList();
    });

    setOpenAdd(false);
  };

  const classes = useStylesMain();
  return (
    <Container className={classes.containermt}>
      <Grid container justify="center">
        <Grid item lg={12} md={12}>
          <Card className={classes.card} align="center">
            <Avatar className={classes.avatar}>
              <Icon className={classes.icon}>person_add</Icon>
            </Avatar>
            <Typography variant="h5" color="primary">
              Registro de tractor
            </Typography>

            <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Nombre Conductor"
                    variant="outlined"
                    fullWidth
                    name="driver"
                    value={user.driver}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Direccion"
                    variant="outlined"
                    fullWidth
                    name="address"
                    value={user.address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Telefono"
                    variant="outlined"
                    fullWidth
                    name="phone"
                    value={user.phone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Placa"
                    variant="outlined"
                    fullWidth
                    name="plate"
                    value={user.plate}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <TextField
                    label="Licencia"
                    variant="outlined"
                    fullWidth
                    name="license"
                    value={user.license}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridmb}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={saveUser}
                    type="submit"
                  >
                    Registrar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RegisterDriver;
