import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import useStylesMain from '../../styles/useStylesMain';
import SalesReport from './SalesReport';
import SaleSoy from './SaleSoy';
import SaleProduct from './SaleProduct';


const AllSale = () => {
  const classes = useStylesMain();


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item >
          <Paper className={classes.card}>
            <SalesReport></SalesReport>
          </Paper>
        </Grid>
        <Grid item >
          <Paper className={classes.card}>
            <SaleSoy></SaleSoy>
          </Paper>
        </Grid>
        <Grid item >
          <Paper className={classes.card}>
            <SaleProduct></SaleProduct>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AllSale;
