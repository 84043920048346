import {
  Button,
  Container,
  Icon,
  Typography,
} from "@material-ui/core";
import React from "react";
import useStylesMain from "../../styles/useStylesMain";
import { getProductsExcel } from "../../state/actions/ProductActions";

const ProductReport = () => {
  const classesMain = useStylesMain();


  const reportProduct = () => {
    const listExcel = async () => {
      const res = await getProductsExcel();
      const file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };
    listExcel();
  };

  return (
    <Container className={classesMain.containermt}>
      <Typography variant="h6" className={classesMain.text_title}>
        TODOS LOS  PRODUCTOS
      </Typography>

     
        <Button variant="contained" color="secondary" onClick={reportProduct}>
          <Icon>search</Icon>
          GENERAR EXCEL
        </Button>
     
    </Container>
  );
};

export default ProductReport;
