import { Button, Container, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { editEntrance, getEntranceOnly } from "../../state/actions/PilActions";
import { useStateValue } from "../../state/store";
import useStylesMain from "../../styles/useStylesMain";

const EditEntrance = ({ setOpenUpdate, refreshList, idProduct  }) => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [pil, setPil] = useState({
    id: idProduct,
    name: "",
    code: "",
    price:0.0
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPil((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getProductIdAsync = async () => {
      const response = await getEntranceOnly(idProduct);
      setPil(response.data);      
    };

    getProductIdAsync();
  }, [idProduct]);

  const saveMilk = async (e) => {
    e.preventDefault();

    const response = await editEntrance(pil);

    if (response.status === 200) {
      setOpenUpdate(false);
      refreshList();
    } else {
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "No es posible actualizar",
        },
      });
    }
  };

  const classesMain = useStylesMain();
  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={8} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR DETALLES
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <TextField
              label="Nombre aporte"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="name"
              value={pil.name}
              onChange={handleChange}
            />
            <TextField
              label="Codigo aporte"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="code"
              value={pil.code}
              onChange={handleChange}
            />

            <TextField
              label="Monto"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="price"
              value={pil.price}
              onChange={handleChange}
            />

            <Button variant="contained" fullWidth color="primary" onClick={saveMilk}>
              ACTUALIZAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditEntrance;
