import {
    Button,
    Container,
    Icon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import { Pagination } from "@material-ui/lab";
import {
    getRoles,
} from "../../state/actions/RoleActions";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import EditRole from "./EditRole";
import RegisterRole from "./RegisterRole";

const Roles = () => {
    const classesMain = useStylesMain();
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [idRole, setIdRole] = useState("");

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const [requestRoles, setRequestRoles] = useState({
        pageIndex: 1,
        pageSize: 20,
        search: "",
    });

    const [paginadorRoles, setPaginadorRoles] = useState({
        count: 0,
        pageIndex: 0,
        pageSize: 0,
        PageCount: 0,
        data: [],
    });

    const handleChange = (event, value) => {
        setRequestRoles((anterior) => ({
            ...anterior,
            pageIndex: value,
        }));
    };

    useEffect(() => {
        const getListaRoles = async () => {
            const response = await getRoles(requestRoles);
            setPaginadorRoles(response.data);
        };

        getListaRoles();
    }, [requestRoles]);

    const editaRole = (id) => {
        setOpen(true);
        setIdRole(id);
    };

    const refreshList = () => {
        const getListaRoles = async () => {
            const response = await getRoles(requestRoles);
            setPaginadorRoles(response.data);
        };

        getListaRoles();
    };

    const agregarRole = () => {
        setOpenAdd(true);
    };

    return (
        <Container className={classesMain.containermt}>
            <Typography variant="h4" className={classesMain.text_title}>
                ROLES
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">

                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classesMain.buttonAgregar}
                                    onClick={agregarRole}
                                >
                                    <Icon>add</Icon>
                                    NUEVO ROL
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Rol</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginadorRoles.data.map((role) => (
                            <TableRow key={role.id}>
                                <TableCell>{role.id}</TableCell>
                                <TableCell>{role.rolName}</TableCell>
                                <TableCell>
                                    <Button disabled
                                        variant="contained"
                                        color="primary"
                                        onClick={() => editaRole(role.id)}
                                    >
                                        <Icon>edit</Icon>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={paginadorRoles.pageCount}
                page={paginadorRoles.pageIndex}
                onChange={handleChange}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <EditRole
                    idRole={idRole}
                    setOpen={setOpen}
                    refreshList={refreshList}
                ></EditRole>

                <DialogActions>
                    <Button onClick={handleClose} color="secondary" fullWidth variant="contained">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openAdd}
                onClose={handleCloseAdd}
                aria-labelledby="form-dialog-title"
            >
                <RegisterRole
                    setOpenAdd={setOpenAdd}
                    refreshList={refreshList}
                ></RegisterRole>

                
                <DialogActions>
                    <Button onClick={handleCloseAdd} color="secondary" fullWidth variant="contained">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Roles;
