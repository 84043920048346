import {
    Avatar,
    Button,
    Card,
    Container,
    Grid,
    Icon,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { registerRole } from "../../state/actions/RoleActions";
import { useStateValue } from "../../state/store";
import useStylesMain from "../../styles/useStylesMain";

const RegisterRole = ({ setOpenAdd, refreshList }) => {
    const [{ }, dispatch] = useStateValue();
    const [role, setRole] = useState({
        rolName: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRole((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const saveRole = () => {
        registerRole(role, dispatch).then((response) => {
            refreshList();
        });

        setOpenAdd(false);
    };

    const classes = useStylesMain();
    return (
        <Container className={classes.containermt}>
            <Grid container justify="center">
                <Grid item lg={12} md={12}>
                    <Card className={classes.card} align="center">
                        <Avatar className={classes.avatar}>
                            <Icon className={classes.icon}>person_add</Icon>
                        </Avatar>
                        <Typography variant="h5" color="primary">
                            Registro de Rol
                        </Typography>

                        <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
                            <Grid container spacing={1}>

                                <Grid item md={12} xs={12} className={classes.gridmb}>
                                    <TextField
                                        label="Nombre"
                                        variant="outlined"
                                        fullWidth
                                        name="rolName"
                                        value={role.rolName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12} className={classes.gridmb}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        onClick={saveRole}
                                        type="submit"
                                    >
                                        Registrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default RegisterRole;
