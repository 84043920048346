import {
  Button,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";
import {
  deleteOrderInvoce,
  getDeliveredPdf,
  getProductoByAssociate,
  registerDelivered,
} from "../../state/actions/ProductActions";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const OrderUser = ({ itemData, setOpen, setRequestSales }) => {
  const mensajeEnvio = "No Entregado";
  const classes = makeStyles();
  const classesMain = useStylesMain();

  const [productsData, setProductsData] = useState({
    data: [],
  });

  const [name, setName] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date());

  useEffect(() => {
    const getListaProductos = async () => {
      const response = await getProductoByAssociate(itemData.id);
      setProductsData(response);
    };

    getListaProductos();
  }, []);

  const imageData = (extensionDoc, dataDoc) => {
    let imagenDefault = "data:image/" + extensionDoc + ";base64," + dataDoc;
    return imagenDefault;
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const addDelivered = async () => {
    const item = {
      id: itemData.id,
      deliveredTo: name,
      dateDelivered: dateFrom
    };

    registerDelivered(item).then((response) => {
      setRequestSales(() => ({
        pageIndex: 1,
        pageSize: 3,
        search: "",
      }));

      const printer = {
        code: itemData.code,
        id: itemData.id,
        lastName: itemData.lastName,
        name: itemData.name,
        price: itemData.price,
        invoce: response.data,
        group: itemData.group,
      };

      const listPdf = async () => {
        const res = await getDeliveredPdf(printer);
        const file = new Blob([res.data], {
          type: "application/pdf",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      };
      listPdf();

      setOpen(false);
    });
  };

  const deleteInvoce = (idOrder) => {
    deleteOrderInvoce(idOrder).then((response) => {
      const getListaProductos = async () => {
        const response = await getProductoByAssociate(itemData.id);
        setProductsData(response);
      };

      getListaProductos();
    });
  };

  return (
    <>
      <Grid container spacing={2} className={classes.papperPadding}>
        <Grid item md={8} xs={12}>
          <Typography variant="h7" className={classesMain.text_title}>
            PEDIDO DE: {itemData.lastName + " " + itemData.name}
          </Typography>
          <Typography variant="body2" className={classes.text_envio}>
            Codigo: {itemData.code}
          </Typography>
          <br></br>
          <TextField
            label="Nombre a entregar"
            variant="outlined"
            fullWidth
            className={classesMain.gridmb}
            InputLabelProps={{
              shrink: true,
            }}
            name="name"
            value={name}
            onChange={handleChange}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={dateFrom}
              onChange={setDateFrom}
              margin="normal"
              fullWidth
              id="fecha-publicacion-id"
              label="Fecha de entrega"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <Divider className={classes.divider} />
          <Typography variant="h6" className={classesMain.text_title}>
            DETALLE DE PEDIDO
          </Typography>
          <TableContainer className={classesMain.gridmb}>
            <Table>
              <TableBody>
                {productsData.data.map((producto) => (
                  <TableRow>
                    <TableCell>
                      <CardMedia
                        className={classes.imgProductoPC}
                        image={imageData(
                          producto.extensionDoc,
                          producto.dataDoc
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.text_detalle}>
                        {producto.nameProduct}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.text_detalle}>
                        {producto.quantity} x {producto.priceProduct} ={" "}
                        {producto.priceTotal}Bs
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => deleteInvoce(producto.id)}
                        color="primary"
                        fullWidth
                        variant="contained"
                      >
                        Eliminar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item md={4} xs={12}>
          <TableContainer component={Paper} square>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="h6" className={classesMain.text_title}>
                      RESUMEN DEL PEDIDO
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography className={classesMain.text_title}>
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classesMain.text_title}>
                      {itemData.price}Bs
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={addDelivered}
                    >
                      ENTREGAR
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderUser;
