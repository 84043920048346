import { Button, Container, Divider, Typography, Grid, TextField, Switch, } from "@material-ui/core";
import useStylesMain from "../../styles/useStylesMain";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { getListContributionExcel } from "../../state/actions/UserActions";
import { getAllContribution } from "../../state/actions/PilActions";

const Contribution = () => {
    const classes = useStylesMain();
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [loadingEx, setLoadingEx] = useState(false);

    const [inputProduct, setInputProduct] = useState(null);
    const [products, setProducts] = useState({
        data: [],
    });

    useEffect(() => {
        const getListProviders = async () => {
            const response = await getAllContribution();
            setProducts(response.data);
        };

        getListProviders();
    }, []);


    const addSelection = async () => {
        const item = {
            dateFrom: new Date(dateFrom).toLocaleDateString('en-ZA'),
            dateTo: new Date(dateTo).toLocaleDateString('en-ZA'),
            contributionId: inputProduct.id,
            isPay: loadingEx
        };

        const listExcel = async (dataItem) => {
            const res = await getListContributionExcel(dataItem);
            const file = new Blob([res.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        };
        listExcel(item);
    };

    const changeSwitch = async () => {
        setLoadingEx(!loadingEx);
      };



    return (
        <>
            <Container className={classes.containermt}>

                <Typography variant="h6" className={classes.text_title}>
                    APORTES POR FECHA
                </Typography>
                <br></br>
                <Grid container spacing={3}>
                    <Grid item md>
                        <Autocomplete
                            options={products}
                            getOptionLabel={(option) =>
                                option.name
                            }
                            id="controlled-demo"
                            value={inputProduct}
                            onChange={(event, newValue) => {
                                setInputProduct(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Aportes"
                                    variant="outlined"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item>

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <KeyboardDatePicker
                                value={dateFrom}
                                onChange={setDateFrom}
                                id="fecha-publicacion-id"
                                label="Desde fecha"
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <KeyboardDatePicker
                                value={dateTo}
                                onChange={setDateTo}
                                id="fecha-publicacion-id"
                                label="Hasta fecha"
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <Typography>Seleccione  para pagados:</Typography>
                    </Grid>
                    <Grid item>
                        <Switch
                            checked={loadingEx}
                            onChange={changeSwitch}
                            color="primary"
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={addSelection}>
                            GENERAR EXCEL
                        </Button>
                    </Grid>

                </Grid>

                <br></br>
                <Divider className={classes.divider} />
            </Container>
        </>
    );
};

export default Contribution;
