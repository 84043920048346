import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { saveIncrease } from "../../state/actions/ProductActions";

const AddIncrease = ({ setOpenIncrease, idProduct, refreshList }) => {
  const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
  const [unit, setUnit] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [producto, setProducto] = useState({
    dateMerge: new Date(),
    quantity: 0,
    priceUnit: 0,
    priceTotal: 0,
    productId: idProduct,
    type : "Add"
  });

  const saveMergeData = async () => {
    producto.dateMerge = fechaSeleccionada;
    producto.priceUnit = unit;
    producto.quantity = quantity;
    saveIncrease(producto).then((response) => {
      refreshList();
    });

    setOpenIncrease(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProducto((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeUnit = (e) => {
    const item = e.target.value;
    setUnit(item);
    handleCalculate(item, quantity);
  };

  const handleChangeQuantity = (e) => {
    const item = e.target.value;
    setQuantity(item);
    handleCalculate(unit, item);
  };

  const handleCalculate = (item, quantity) => {
    if (parseFloat(item) > 0 && parseFloat(quantity) > 0) {
      producto.priceTotal = parseFloat(quantity) * parseFloat(item);
    } else {
      producto.priceTotal = 0;
    }
  };

  const classes = makeStyles();
  const classesMain = useStylesMain();

  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={6} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR INCREMENTO
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <TextField
              label="Cantidad producto"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="quantity"
              value={quantity}
              onChange={handleChangeQuantity}
            />
            <TextField
              label="Precio unidad"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="unit"
              value={unit}
              onChange={handleChangeUnit}
            />

            <TextField
              label="Precio total"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="priceTotal"
              value={producto.priceTotal}
              onChange={handleChange}
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={fechaSeleccionada}
                onChange={setFechaSeleccionada}
                margin="normal"
                id="fecha-publicacion-id"
                label="Seleccione Fecha"
                format="dd/MM/yyyy"
                fullWidth
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>

            <Button variant="contained" color="primary" fullWidth onClick={saveMergeData}>
              AGREGAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddIncrease;
