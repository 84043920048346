import {
  Button,
  Container,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  getDebtReportPdf,
} from "../../state/actions/ProductActions";
import useStylesMain from "../../styles/useStylesMain";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useState } from "react";

const DebtReport = () => {
  const classes = useStylesMain();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [code, setCode] = useState("");

  const addSelection = async () => {
    const item = {
      code: code,
      dateFrom: new Date(dateFrom).toLocaleDateString('en-ZA'),
      dateTo: new Date(dateTo).toLocaleDateString('en-ZA'),
    };

    const listExcel = async (dataItem) => {
      const res = await getDebtReportPdf(dataItem);
      const file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };
    listExcel(item);
  };

  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  return (
    <>
      <Container className={classes.containermt}>
        <Typography variant="h6" className={classes.text_title}>
          DEUDAS Y PAGO POR SOCIO
        </Typography>
        <br></br>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              label="Codigo de socio"
              variant="outlined"
              className={classes.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="code"
              value={code}
              onChange={handleChangeCode}
            />
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateFrom}
                onChange={setDateFrom}
                // margin="normal"
                id="fecha-publicacion-id"
                label="Seleccione inicio"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateTo}
                onChange={setDateTo}
                // margin="normal"
                id="fecha-publicacion-id"
                label="Seleccione fin"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={addSelection}>
              GENERAR EXCEL
            </Button>
          </Grid>
        </Grid>


      </Container>
    </>
  );
};

export default DebtReport;
