import {
  Button,
  Container,
  Divider,
  
  Typography,
  
  Grid,
} from "@material-ui/core";
import {
  getDebtExcel,
} from "../../state/actions/UserActions";
import useStylesMain from "../../styles/useStylesMain";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const DebtExcel = () => {
  const classes = useStylesMain();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [code, setCode] = useState(0);
  const [associateExcel, setAssociateExcel] = useState({
    listAssociate: [],
  });

  const addSelection = async () => {
    const item = {
      code: code,
      dateFrom: new Date(dateFrom).toLocaleDateString('en-ZA'),
      dateTo: new Date(dateTo).toLocaleDateString('en-ZA'),
    };

    const list = async (itemData) => {
      const responsea = await getDebtExcel(itemData);
      setAssociateExcel(responsea.data);
      
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(responsea.data.listAssociate);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Lista Socios Deudores" + fileExtension);
    };
    list(item);
  };

  return (
    <>
      <Container className={classes.containermt}>
        <Typography variant="h6" className={classes.text_title}>
          REPORTE DE DEUDAS
        </Typography>
        <br></br>
        <Grid container spacing={3}>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateFrom}
                onChange={setDateFrom}
                // margin="normal"
                id="fecha-publicacion-id"
                label="Seleccione inicio"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateTo}
                onChange={setDateTo}
                // margin="normal"
                id="fecha-publicacion-id"
                label="Seleccione fin"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={addSelection}>
              GENERAR EXCEL
            </Button>
          </Grid>
        </Grid>
        <br></br>
        <Divider className={classes.divider} />
        
      </Container>
    </>
  );
};

export default DebtExcel;
