import { Button, Container, Divider, Typography, Grid, } from "@material-ui/core";
import { getSalesReportPdf } from "../../state/actions/ProductActions";
import useStylesMain from "../../styles/useStylesMain";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useState } from "react";

const SalesReport = () => {
  const classes = useStylesMain();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());


  const addSelection = async () => {
    const item = {
      dateFrom: new Date(dateFrom).toLocaleDateString('en-ZA'),
      dateTo: new Date(dateTo).toLocaleDateString('en-ZA'),
    };

    const listExcel = async (dataItem) => {
      // Asegúrate de que getSalesReportExcel esté implementado correctamente 
      // para llamar a tu nuevo endpoint de Excel
      const res = await getSalesReportPdf(dataItem);
      const file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // Construir una URL a partir del archivo
      const fileURL = URL.createObjectURL(file);
      // Abrir la URL en una nueva ventana
      window.open(fileURL);
    };
    listExcel(item);
  };



  return (
    <>
      <Container className={classes.containermt}>

        <Typography variant="h6" className={classes.text_title}>
          VENTAS POR FECHA
        </Typography>
        <br></br>
        <Grid container spacing={3}>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateFrom}
                onChange={setDateFrom}
                // margin="normal"
                id="fecha-publicacion-id"
                label="Desde fecha"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateTo}
                onChange={setDateTo}
                // margin="normal"
                id="fecha-publicacion-id"
                label="Hasta fecha"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={addSelection}>
              GENERAR EXCEL
            </Button>
          </Grid>

        </Grid>

        <br></br>
        <Divider className={classes.divider} />
      </Container>
    </>
  );
};

export default SalesReport;
