import AxiosRequest from "../axios/AxiosRequest";
import axios from "axios";
//import { uploadImage } from '../firebase';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;


export const getTractors = (request) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/tractor/tractors?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`)
      .then(response => {
          resolve(response);
      })
      .catch( error => {
          resolve(error.response);
      });
  });
}


export const registerTractor = (usuario) => {
  return new Promise( (resolve, eject) =>{
    AxiosRequest.post("/api/tractor/tractor", usuario).then( response => { 
          resolve(response);
      })
      .catch((error)=> {
          resolve(error.response);
      })
  });
}

export const getAllTractors = () => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly('/api/tractor')
      .then( response =>{
          resolve(response);
      });
  })    
};

export const getAllAssociate = () => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly('/api/tractor/associates')
      .then( response =>{
          resolve(response);
      });
  })    
};


export const registerTractorAssociate = async (producto)=>{
 
  
  return new Promise((resolve, eject)=>{
    AxiosRequest.post("/api/tractor/tractorAssociate", producto)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      });
  });

}


export const getAllWorks = (request) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/tractor/allworks?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}&date=${request.date}`).then( response =>{
          resolve(response);
      });
  })    
};

export const getAllPayWorks = (request) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/tractor/allPaidworks?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}&date=${request.date}`).then( response =>{
          resolve(response);
      });
  })    
};

export const editPaid = async (pil) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post("/api/tractor/updatePaid", pil)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getDriverById = (id) => {
  return new Promise( (resolve, eject) => {
    AxiosRequest.getOnly(`/api/tractor/driver/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}

export const updateDriverList = (id, user) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.put(`/api/tractor/updateDriver/${id}`, user)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response)
      });
  })
}

export const deleteDriverList = (id) => {
  return new Promise((resolve, eject) => {
    AxiosRequest.post(`/api/tractor/deleteDriver/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response)
      });
  })
}
